import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { setCustomerlist, setLoading, storeInLocalStore } from 'store/reducers/mainSlice';
import Loader from 'ui-component/Loader';
import Pagination from 'ui-component/Pagination';
import Scroller from 'ui-component/Scroller';
import NewShipments from './AddShipments';
import moment from 'moment';
import { FaPlus, FaSearch } from 'react-icons/fa';
import Filter from 'ui-component/filter-menu/Filter';
import { CommonDateOnlyFormat } from 'helpers/common.helper';
import { Skeleton, Tooltip } from '@mui/material';
import toast from 'react-hot-toast';
import NoRecordFoundIcon from 'assets/images/icons/no-records-found.svg';
import PermissionAllowed from 'ui-component/permissions/PermissionAllowed';
import PrivacyModeContent from 'ui-component/PrivacyModeContent';
import usePermissions from 'hooks/usePermissions';
import usePageTitle from 'hooks/usePageTitle';
import { ViewDetailsIcon, DeleteIcon2 } from 'ui-component/custom-icons/icons';
import DeleteConfirmation from 'ui-component/confirmation-dialogs/deleteConfirmation/DeleteConfirmation';

const Shipments = () => {
	const [setPageTitle] = usePageTitle('Shipments');
	useEffect(() => {
		return () => {
			setPageTitle(null);
		};
	}, []);
	const [params, setParams] = useSearchParams();
	const history = useNavigate();
	const [allPermissions, hasEveryPermission] = usePermissions();
	const [alertMessage, setAlertMessage] = useState('');
	const [shipmentList, setShipmentList] = useState([]);
	const [show, setShow] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [privacyMode, setPrivacyMode] = useState(false);
	const permission = useSelector((state) => state.main.user.permissionToAction);
	const currentUserRole = useSelector((state) => state.main.user?.role[0]?.name);
	// const customerList = useSelector((state) => state.main.setcustomerlist);
	const [pagination, setpagination] = useState([]);
	const [newShow, setNewShow] = useState(false);
	const dispatch = useDispatch();
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [selectedShipment, setSelectedShipment] = useState(null);
	const [searchField, setSearchField] = useState();
	const [searchTimeout, setSearchTimeout] = useState(null);
	const [filters, setFilters] = useState([]);
	const [activeFilters, setActiveFilters] = useState({});
	const [isFiltersloading, setIsFiltersloading] = useState(false);
	const [isFirstLoad, setIsFirstLoad] = useState(true);

	const handleNewClose = () => setNewShow(false);

	const getShipmentList = () => {
		setIsLoading(true);
		api.getShipmentList().then((res) => {
			setIsLoading(false);
			setShipmentList(res.data.data);
			setpagination(res.data);
			isFirstLoad && setIsFirstLoad(false);
		});
	};
	const handleSearch = (e) => {
		let text = e.target.value;
		setSearchField(text);

		if (searchTimeout) {
			clearTimeout(searchTimeout);
		}

		let timeout = setTimeout(() => {
			setIsLoading(true);
			if (text.length > 2) {
				api.filterShipments(activeFilters, text).then((res) => {
					setIsLoading(false);
					setShipmentList(res.data.data);
					setpagination(res.data);
				});
			} else if (text.length > 0 && text.length <= 2) {
				setIsLoading(false);
			} else {
				api.filterShipments(activeFilters, null).then((res) => {
					setIsLoading(false);
					setShipmentList(res.data.data);
					setpagination(res.data);
				});
			}
		}, 1000);

		setSearchTimeout(timeout);
	};

	const viewData = (source, id) => {
		if (source === 'shipment') {
			history(`/shipments/${id}`);
		} else if (source === 'picklist') {
			window.open(`/picklists/${id}`, '_blank');
		} else if (source === 'sales_order') {
			window.open(`/sales/sales-orders/${id}`, '_blank');
		} else if (source === 'contact') {
			window.open(`/contacts/${id}`, '_blank');
		}
	};


	useEffect(() => {
		!isFirstLoad && handleFilter();
	}, [activeFilters]);

	const handleFilter = (activeF = null, searchF = null) => {
		setIsLoading(true);
		api.filterShipments(activeFilters, searchField?.length > 2 ? searchField : null).then((res) => {
			setIsLoading(false);
			setShipmentList(res.data.data);
			setpagination(res.data);
		});
	};

	useEffect(() => {
		setTimeout(() => {
			verifyPrivacyMode();
		}, 100);
	}, []);

	const verifyPrivacyMode = () => {
		const getPrivacyMode = sessionStorage.getItem('privacyMode');
		if (!getPrivacyMode) {
		} else {
			if (getPrivacyMode === 'true') {
				setPrivacyMode(true);
			} else {
				setPrivacyMode(false);
			}
		}
	};

	const handleDeleteShipment = (data) => {
		setSelectedShipment(data);
		setShowDeleteModal(true)
	};

	const handleClose = () => {
		setShowDeleteModal(false);
	}

	const handleData = (data) => {
		setShipmentList(data.data);
		setpagination(data);
	};

	useEffect(() => {
		getShipmentList();
		getFiltersList();
	}, []);

	useEffect(() => {
		if (params.get('create') == 'shipment') {
			setParams({});
			setNewShow(true);
		}
	});

	const tableHeaderStyles = {
		position: 'sticky',
		top: 0,
		backgroundColor: '#f2f2f2',
		zIndex: 1
	};
	const getFiltersList = () => {
		setIsFiltersloading(true);
		api.getShipmentFilters().then((res) => {
			setFilters(res.data);
			setIsFiltersloading(false);
		});
	};

	const handleCopyBtn = (link, index) => {
		navigator.clipboard.writeText(link);
		setAlertMessage({ ...alertMessage, copied: { message: 'Copied', index: index } });
		setTimeout(() => {
			setAlertMessage({ alertMessage, copied: '' });
		}, 2000);
	};

	const onAddNewShipment = (data) => {
		toast.promise(
			api
				.createShipments({
					transit_company_id: data?.ship_to,
					shipping_company_id: data?.ship_with,
					tracking_no: data?.tracking_number,
					package_ids: data?.packages
				})
				.then((res) => {
					setNewShow(false);

					if (res?.status) {
						handleFilter();
					}
					return res?.status ? res : null;
				}),
			{
				loading: 'Creating SShipment...',
				success: (data) => `${data.message}`,
				error: <b>Failed to create shipment</b>
			}
		);
	};

	const handleDelete = () => {
		toast.promise(
			api.deleteShipment(selectedShipment?.id).then((res) => {
				if (res?.status) {
					getShipmentList()
					handleClose();
					setSelectedShipment(null);
				}
				return res?.status ? res : null;
			}),
			{
				loading: 'Deleting Shipment...',
				success: (data) => `${data.message}`,
				error: <b>Failed to delete shipment</b>
			}
		);
	}

	return (
		<>
			<div className="row">
				{/* Area Chart */}
				<div className="col-xl-12 col-lg-12">
					<div className="section-head">
						<h2></h2>
					</div>
					<div className="card shadow-sm bg-white mb-4" style={{ paddingBottom: '7px' }}>
						<div className="d-flex align-items-center justify-content-between TableSerachBar">
							<div>
								<Filter activeFilters={activeFilters} onSubmit={setActiveFilters} filters={filters} />
							</div>
							<form className="form-inline navbar-search" onSubmit={(e) => e.preventDefault()}>
								<div className="input-group">
									<input
										type="text"
										className="form-control bg-white border"
										placeholder="Search"
										aria-label="Search"
										aria-describedby="basic-addon2"
										onChange={handleSearch}
										style={{ width: '100%' }}
									/>
									<span className="searchIcon">
										<FaSearch />
									</span>
								</div>
							</form>
						</div>
						<div className="table-responsive-xl tableData customersList p-2">
							<Scroller extraHeight={45}>
								<table className="table pm-table">
									<thead>
										<tr className="sticky-header">
											<th scope="col">Provider</th>
											<th scope="col">Tracking Number</th>
											<th scope="col">Picklist</th>
											<th scope="col">Reference</th>
											<th scope="col">Receiver</th>
											<th scope="col">Created</th>
										</tr>
									</thead>
									<tbody>
										{isLoading ? (
											<>
												{[...Array(5)].map((e, i) => (
													<tr>
														{[...Array(7)].map((e, i) => (
															<td>
																<Skeleton variant="rounded" width={'100%'} height={20}></Skeleton>
															</td>
														))}
													</tr>
												))}
											</>
										) : (
											<>
												{shipmentList?.map((row, index) => {
													return (
														<>
                                                            <tr key={index} className="hover-row h-35">

																<td className="align-middle">
                                                                    {row?.shipping_company?.name}
                                                                    {/* {row?.shipping_company?.name} */}
                                                                </td>
																<td className="align-middle">
																	<div
																		className="cursor-pointer text-primary"
																		onClick={() => {
																			const url = `${row?.tracking_link}`;
																			window.open(url, '_blank');
																		}}
																	>
                                                                    	{row?.tracking_number}
																	</div>
                                                                </td>
																<td className={`align-middle`}>
																	{
																		row?.shipments?.length > 0 && (
																			<>
																				<div className="d-flex justify-left items-center gap-4">
																					{
																						row?.shipments?.slice(0, 2).map((shipment, index) => (
																							<React.Fragment key={index}>
																								<div 
																									className={`${hasEveryPermission(['View Picklists (Button)']) ? 'cursor-pointer text-primary' : ''}`}
																									onClick={() => {
																										if (hasEveryPermission(['View Picklists (Button)'])) {
																											viewData('picklist', shipment?.picklist?.id);
																										}
																										
																									}}
																								>
																									{shipment?.picklist?.picklist_no}
																									{index < Math.min(row.shipments.length, 2) - 1 && ','}
																								</div>
																							</React.Fragment>
																						))
																					}
																					{
																						row?.shipments?.length > 2 && (
																							<div className="more_label_container">
																								+{row.shipments.length - 2} more
																							</div>
																						)
																					}
																					
																				</div>
																			</>
																		)
																	}
																</td>
																<td className={`align-middle`}>
																	{
																		row?.shipments?.length > 0 && (
																			<>
																				<div className="d-flex justify-left items-center gap-4">
																					{
																						row?.shipments?.slice(0, 2).map((shipment, index) => (
																							<React.Fragment key={index}>
																								<div 
																									className={`${hasEveryPermission(['View Sales Orders Details']) ? 'cursor-pointer text-primary' : ''}`}
																									onClick={() => {
																										if (hasEveryPermission(['View Sales Orders Details'])) {
																											viewData('sales_order', shipment?.sales_order?.id);
																										}
																										
																									}}
																								>
																									{shipment?.sales_order?.sales_order_number}
																									{index < Math.min(row.shipments.length, 2) - 1 && ','}
																								</div>
																							</React.Fragment>
																						))
																					}
																					{
																						row?.shipments?.length > 2 && (
																							<div className="more_label_container">
																								+{row.shipments.length - 2} more
																							</div>
																						)
																					}
																					
																				</div>
																			</>
																		)
																	}
                                                                </td>
																<td className={`align-middle`}>
																	{
																		row?.shipments?.length > 0 && (
																			<>
																				<div className="d-flex justify-left items-center gap-4">
																					{
																						row?.shipments?.slice(0, 1).map((shipment, index) => (
																							<React.Fragment key={index}>
																								<div 
																									className={`${hasEveryPermission(['View Contact (Button)']) ? 'cursor-pointer text-primary' : ''}`}
																									onClick={() => {
																										if (hasEveryPermission(['View Contact (Button)'])) {
																											viewData('contact', shipment?.receiver?.id);
																										}
																										
																									}}
																								>
																									{shipment?.receiver?.name}
																									{index < Math.min(row.shipments.length, 1) - 1 && ','}
																								</div>
																							</React.Fragment>
																						))
																					}
																					{
																						row?.shipments?.length > 2 && (
																							<div className="more_label_container">
																								+{row.shipments.length - 1} more
																							</div>
																						)
																					}
																					
																				</div>
																			</>
																		)
																	}
                                                                </td>
																<td className="align-middle" >
                                                                    {moment(row?.created_date).format(CommonDateOnlyFormat)}
                                                                </td>

																<td className="hover-button-container" >
																	<div>
																		<div className="hover-button">
																			
																			<PermissionAllowed permissions={['View Shipment (Button)']}>
																				<Tooltip title={'View'} arrow placement="top">
																					<div
																						className='btn btn-primary btn-outlined-main white-var btn-sm cursor-pointer'
																						style={{ marginLeft: '8px' }}
																						onClick={() => {
																								viewData('shipment', row.id);
																						}}
																					>
																						<ViewDetailsIcon height={'16'} width={'16'} /> 
																					</div>
                                                                                </Tooltip>
																			</PermissionAllowed>

																			<PermissionAllowed permissions={['Delete Shipment (Button)']}>
																				<Tooltip title={'Delete'} arrow placement="top">
																					<div
																						className='btn btn-danger btn-outlined-danger white-var btn-sm cursor-pointer'
																						style={{ marginLeft: '8px' }}
																						onClick={() => {
																							handleDeleteShipment(row);
																						}}
																					>
																						<DeleteIcon2 height={'20'} width={'20'} /> 
																						{/* <CheckCircleIcon height={'20'} width={'20'} /> Complete */}
																					</div>
                                                                                </Tooltip>
																			</PermissionAllowed>
																		</div>
																	</div>
																</td>
															</tr>
														</>
													);
												})}
												{
													shipmentList?.length < 1 && (
														<tr className="no-hover">
															<td colSpan={5}>
																<div className="not-found-container">
																	<div className="not-found-icon">
																		<img src={NoRecordFoundIcon} style={{ height: '200px', width: '200px' }} />
																	</div>
																	<h6 className="not-found-text">No Data Available</h6>

																</div>
															</td>
														</tr>
													)
												}
											</>
										)}
									</tbody>
								</table>
							</Scroller>
						</div>
						<Pagination pagination={pagination} data={handleData} onLoading={(e) => setIsLoading(e)} />
					</div>
				</div>
			</div>
			
			
            {showDeleteModal && (
                <DeleteConfirmation
                    show={showDeleteModal}
                    handleClose={handleClose}
                    handleDelete={handleDelete}
                    title="Confirm"
                    message={`Are you sure you want to delete shipment ${selectedShipment?.reference_no}?`}
                    customDeleteButtonText={`Yes, Delete shipment ${selectedShipment?.reference_no}`}
                />
            )}
		</>
	);
};

export default Shipments;
