import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';
import moment from 'moment';
import Acceptpopup from '../estimation/Acceptpopup';
import { useSelector, useDispatch } from 'react-redux';
import { setReturncomponent, setEstimatedata, storeInLocalStore } from 'store/reducers/mainSlice';
import 'react-datepicker/dist/react-datepicker.css';
import { FaArrowRight, FaEdit, FaMinus, FaPlus, FaRegSave, FaSave, FaPaperPlane } from 'react-icons/fa';
import { toast } from 'react-hot-toast';
import PermissionAllowed from 'ui-component/permissions/PermissionAllowed';
import usePermissions from 'hooks/usePermissions';
import { FaPaperclip, FaDownload, FaPen, FaRegTrashCan } from 'react-icons/fa6';
import PaymentsComponent from '../estimation/paymentComponents/PaymentsComponent';
import ShipmentsComponent from '../estimation/shipmentComponents/NewShipmentComponent';
import { LuChevronDown } from 'react-icons/lu';
import { Dropdown } from 'react-bootstrap';
import { saveAs } from 'file-saver';
import Notes from 'ui-component/notes';
import PrivacyModeContent from 'ui-component/PrivacyModeContent';
import ActionConfirmationModal from 'ui-component/confirmation-dialogs/actionConfirmationModal/ActionConfirmationModal';
import { PiInfoFill } from 'react-icons/pi';
import { getCurrencySymbol, numberFormatter } from 'utils/currency';
import { CommonDateOnlyFormat } from 'helpers/common.helper';
import config from 'config.js';
import { useQuery, useQueryClient } from 'react-query';
import { getLocalStorageCacheItem, setLocalStorageCacheItem } from 'helpers/common.helper';
import md5 from 'md5-hash';
import AcceptOrderModal from 'ui-component/confirmation-dialogs/acceptOrderModal/AcceptOrderModal';
import { RegisterPaymentIcon, ProcessOrderIcon, DeleteIcon2, InvoiceIcon } from 'ui-component/custom-icons/icons';
import DeleteConfirmation from 'ui-component/confirmation-dialogs/deleteConfirmation/DeleteConfirmation';
import agreementImage from 'assets/images/agreement.svg';
import StatusBadge from 'ui-component/StatusBadge';
import AddToPurchaseListConfirmation from 'ui-component/confirmation-dialogs/addToPurchaseListConfirmation/AddToPurchaseListConfirmation';

import SendEmailModal from '../estimation/modals/SendEmailModal';
import PurchaseOrderModal from '../estimation/modals/PurchaseOrderModal';
import ProcessOrderModal from './modals/ProcessOrderModal';
import TimelineStep from 'ui-component/TimelineStep';
// import AWBModal from './modals/AWBModal';
import SalesOrderDetailInvoice from './SalesOrderDetailInvoice';
import PurchaseOrderComponent from './components/PurchaseOrderComponent';
import PicklistComponent from './components/PicklistComponent';
import InvoiceComponent from './components/InvoiceComponent';
import QoutationComponent from './components/QoutationComponent';
import Timeline from 'ui-component/Timeline';


const SalesOrderDetails = () => {
	const { id } = useParams();
	const [userName] = useSelector((state) => [state.main.user.first_name + ' ' + state.main.user.last_name]);
	const userEmail = useSelector((state) => state.main.user?.email);

	const [isInternalLoading, setInternalLoading] = useState(false);
	const [selectedCurrency] = useSelector((state) => [state.main.selectedCurrency]);

    // const [allPermissions, hasEveryPermission] = usePermissions();
	// Customer Create
	const [selectedSalesOrder, setSelectedSalesOrder] = useState();
	const [salesTypePath, setSalesTypePath] = useState('');
	const [salesType, setSalesType] = useState('');
	const [addToPl, setAddToPl] = useState(null);
	const [showDeletePayment, setShowDeletePayment] = useState(null);
	const [addToPlId, setAddToPlId] = useState(null);
	const [showActions, setShowActions] = useState(false);
	const [showDeletePopup, setShowDeletePopup] = useState(false);
	const [selectedPaymentId, setSelectedPaymentId] = useState(null);
	const [showCancelPopup, setShowCancelPopup] = useState(false);
	const [showClosePopup, setShowClosePopup] = useState(false);
	const [openSendEmailPopup, setOpenSendEmailPopup] = useState(false);
	const [sendingSalesOrder, setSendingSalesOrder] = useState(false);
	const [selectedForSending, setSelectedForSending] = useState('');
	const [showPurchaseOrderModal, setShowPurchaseOrderModal] = useState(null);
	const [showProcessOrderModal, setShowProcessOrderModal] = useState(false);
	const [purchaseOrderItems, setPurchaseOrderItems] = useState(null);
	const [hasPayments, setHasPayments] = useState(false);
    const [refreshKey, setRefreshKey] = useState(0);

	const currentDate = moment().format('YYYY-MM-DD');

	const statusAllowedToEdit = [
		'Estimated',
		'Pending Manager',
		'Paid',
		'Pending',
		'Accepted',
		'Draft',
		'Credit Note',
		'Open',
		'Unprocessed'
	];
	const statusAllowedToPay = ['Open'];
	const statusAllowedToDeleteDraft = ['Draft'];
	const statusAllowedToAccept = ['Pending', 'Pending Manager'];
	const statusAllowedToApprove = ['Accepted'];
	const statusAllowedToProcess = ['Paid'];
	const statusAllowedToCancel = ['Draft', 'Open'];
	const statusNotAllowedToCancel = ['Draft', 'Canceled', 'Closed'];
	const statusNotAllowedToClose = ['Draft', 'Canceled', 'Closed'];

	const statusAllowedToPayAndSalesOrder = ['Draft', 'Canceled', 'Closed'];
	const statusNotAllowedToProcessOrder = ['Draft', 'Canceled', 'Closed'];

	const dispatch = useDispatch();

	// End Create
	const history = useNavigate();
	const [modalPrice, setModalprice] = useState('00');

	const [showModal, setShowModal] = useState(false);
	const [shippingRow, setShippingrow] = useState({
		quantity: 1,
		unitPrice: 0,
		totalPrice: 0
	});

	const app_name = config.apps.find((c) => c?.app_urls.includes(window.location.origin))?.app_name || 'abcd';

	const [selectedCustomerinfo, setSelectedCustomerInfo] = useState({});
	const [subTotal, setSubtotal] = useState('00');
	const [grandTotal, setGrandtotal] = useState('00');
	// const [alertMessage, setAlertMessage] = useState();
	const [shipmentData, setShipmentData] = useState([]);
	const [paymentData, setPaymentData] = useState([]);
	const [awbData, setAwbData] = useState([]);
	const [isDisable, setDisable] = useState(false);
	const [deleteMessage, setDeleteMessage] = useState('');

	// const [orderType, setOrderType] = useState('');
	const [orderAgreementsData, setOrderAgreementsData] = useState([]);
	const [contactData, setContactData] = useState(null);
	const [selectedPaymentShipment, setSelectedPaymentShipment] = useState('payment');
	const queryClient = useQueryClient();
	const location = useLocation();
	const pathParts = location.pathname.split('/');

	const openSendEmailModal = (type) => {
		setOpenSendEmailPopup(type);
	};

	const closeSendEmailPopup = () => {
		setOpenSendEmailPopup(false);
	};

	const handleSelectMenu = (item) => {
		setSelectedPaymentShipment(item);
		console.log('selectedPaymentShipment', selectedPaymentShipment);
	};

	const { data: estDetReqData } = useQuery(
		['salesorder-details', id, location, salesTypePath, showActions],
		() => {
			const queryParams = new URLSearchParams(location.search);
            const callbackParam = queryParams.get('callback-action-accept-order');
            
			setRefreshKey(refreshKey + 1);
            setShowActions(true);
            return api.getSalesOrderById(id);
		},
		{
			keepPreviousData: true,
			initialData: (e) => {
				const item = getLocalStorageCacheItem(md5(`salesorder-details-${id}`));
				return item ? item : undefined;
			},
			onSuccess: (e) => {
				setLocalStorageCacheItem(md5(`salesorder-details-${id}`), e, moment().add(3, 'days').format('YYYY-MM-DD'));
			}
		}
	);

	const invalidateEstQuery = () => {
		queryClient.invalidateQueries({ queryKey: ['salesorder-details', id, location, salesTypePath, showActions] });
	};

	const [showAddPaymentModal, setShowAddPaymentModal] = useState(false);

	const [confirmationDialog, setConfirmationDialog] = useState({
		show: false,
		message: '',
		title: '',
		handleConfirm: () => {},
		handleClose: () => {}
	});

	const handleCloseConfirmationDialog = () => {
		setConfirmationDialog({
			show: false,
			message: '',
			title: '',
			handleConfirm: () => {},
			handleClose: () => {}
		});
	};

	const showConfirmationDialog = (
		title,
		message = '',
		onConfirm = () => {},
		onClose = () => {
			handleCloseConfirmationDialog();
		}
	) => {
		setConfirmationDialog({
			show: true,
			message: message,
			title: title,
			handleConfirm: onConfirm,
			handleClose: onClose
		});
	};
	// const handleCloseDeletePopup = () => setShowDeletePopup('');
	const handleClosePopup = () => {
		setShowDeletePopup('')	
		setShowCancelPopup(false)
		setShowClosePopup(false)
		setShowProcessOrderModal(false)
		setShowDeletePayment(false)
	}

	const handleSendEmail = (data) => {
		// setSendingSalesOrder(true)
		// return false;
		// setOpenSendEmailPopup(false)
		setSendingSalesOrder(true);
		toast.promise(
			api.sendSalesOrderByEmail(data).then(
				(res) => {
					invalidateEstQuery();
					// console.log('het')
					// return false
					if (res.status) {
						setOpenSendEmailPopup(false);
					} else {
						toast.error(`Sending email failed. Please try again.`);
					}
					setSendingSalesOrder(false);
					// setIsProcessing(false)

					return res?.status ? res : null;
				},
				() => {}
			),
			{
				loading: `Sending...`,
				success: (data) => `${data.message}`,
				error: <b>Failed to send email</b>
			}
		);
	};

	const handlePlDialogClose = () => {
		setAddToPl(null);
		setAddToPlId(null);
	};
	const handleAddPayment = (data) => {
		invalidateEstQuery();
	};

	const handlePaymentToggle = (index) => {
		setPaymentData((oldData) =>
			oldData?.map((p, i) =>
				i == index
					? {
							...p,
							expand: p?.expand ? false : true
					  }
					: p
			)
		);
	};

	const acceptpopup = (id) => {
		setShowModal(id);
	};

	const addtopurchaselistold = (id) => {
		api.createpurchase(id).then((res) => {
			if (res.status) {
				history(`${salesTypePath}`);
			}
		});
	};

	const handleProcessOrder = () => {
		// setAddToPlId(id);
		// const collection = selectedSalesOrder?.sales_order_items?.map((item) => {
		// 	return {
		// 		id: item?.product?.id,
		// 		sku: item?.product?.sku,
		// 		name: item?.product?.product_name,
		// 		thumbnail_url: item?.product?.thumbnail_url,
		// 		qty: item?.qty,
		// 		currency: selectedSalesOrder?.currency,
		// 		converted_currency_unit_price: item?.converted_currency_unit_price
		// 	};
		// });
		setShowProcessOrderModal(true)
		// setCompiledItems(collection)
	};

	// const addPurchaseOrder = (id) => {

	// 	toast.promise(
	// 		api
	// 			.createDraftPurchaseOrder({ estimation_id: id })
	// 			.then(
	// 				(res) => {
	// 					if (res.status) {
	// 						history(`/purchasing/view/${res?.data?.purchase_invoice_id}`);
	// 					}

	// 					return res?.status ? res : null;
	// 				},
	// 				() => { }
	// 			),
	// 		{
	// 			loading: 'Creating purchase order...',
	// 			success: (data) => `${data.message}`,
	// 			error: <b>Failed to create purchase order</b>
	// 		}
	// 	);

	// }

	const closePurchaseOrderPopup = () => {
		setShowPurchaseOrderModal('');
	};

	const handleUpdatePayment = (data) => {
		invalidateEstQuery();
	};

	const handleDeletePayment = (data) => {

		toast.promise(
			api.deleteEstimatePayment(selectedPaymentId).then((res) => {
				if (res.status) {
					handleClosePopup()
					setSelectedPaymentId(null);
					invalidateEstQuery();
				}

				return res?.status == true ? res : null;
			}),

			{
				loading: 'Deleting Payment...',
				success: (data) => `${data.message}`,
				error: <b>Payment Delete Failed</b>
			}
		);
	};

	const [salesOrderRow, setSalesOrderRow] = useState({
		customer_id: '',
		sales_order_number: '',
		sales_manager: '',
		transit_id: '',
		vatStatus: 'Including VAT',
		vatPrice: 21,
		startDate: currentDate,
		expiresIn: 7,
		currency: 'eur',
		notes: []
	});
	// Row Area
	const [rows, setRows] = useState([{ id: 1, modelId: '', type: '', quantity: 0, unitPrice: 0, total: 0 }]);
	const [nextId, setNextId] = useState(2);
	const options = {
		fields: ['address_components', 'geometry', 'icon', 'name'],
		types: ['establishment']
	};

	//   End Row
	/* Shipping Calculator */

	useEffect(() => {
		setSalesOrderRow((oldData) => ({ ...oldData, sales_manager: selectedCustomerinfo?.sales_manager_id }));
	}, [selectedCustomerinfo]);

	const [currentAppName, setCurrentAppName] = useState();
	const [currentClientName, setCurrentClientName] = useState();

	useEffect(() => {
		initiateDatas();
		setCurrentAppName(selectedSalesOrder?.settings?.client_code);
		setCurrentClientName(selectedSalesOrder?.settings?.client_name);

		const subPath = pathParts;
		subPath.pop();

		setSalesTypePath(subPath.join('/'));
	}, [location]);

	// const getSettings = () => {
	// 	api.getSettings().then((res) => {
	// 		//
	// 	});
	// };

	useEffect(() => {
		if (estDetReqData?.data?.data) {
			// getSettings()
			setSelectedSalesOrder(estDetReqData?.data?.data);
			if (estDetReqData?.data?.data?.payments) {
				setPaymentData([...estDetReqData?.data?.data?.payments]);
			}
			if (estDetReqData?.data?.data?.shipments) {
				setShipmentData([...estDetReqData?.data?.data?.awb]);
			}
            setSalesType('sales-order');
			
            setInternalLoading(false)
			// setContactData(estDetReqData?.data?.data.customer);
			setOrderAgreementsData(estDetReqData?.data?.data.order_agreements);
		}
	}, [estDetReqData]);

	const initiateDatas = () => {
		if (id) {
		} else {
			history(`/sales/sales-orders`);
		}
	};

	const handleCreatePurchaseOrder = (details, items) => {
		// console.log('details', details);
		// console.log('items', items);
		let data = details;
		data['items'] = items;
	}

	const addNote = (noteInputValue, noteType, mentions, noteInputPlain) => {
		toast.promise(
			api
				.newSalesOrderNote(id, {
					details: noteInputValue,
					detail_plain: noteInputPlain,
					type: 'public',
					todo: noteType == 'todo' ? true : false,
					mentions
				})
				.then(
					(res) => {
						if (res.status) {
							setSalesOrderRow((oldData) => ({
								...oldData,
								notes: [
									{
										id: res?.data?.id,
										details: noteInputValue,
										date: moment(res?.data?.created_at).format('YYYY-MM-DD'),
										time: moment(res?.data?.created_at).format('HH:mm'),
										user_name: userName,
										expand: false,
										isDeletable: true
									},
									...oldData?.notes
								]
							}));
						}

						return res?.status ? res : null;
					},
					() => {}
				),
			{
				loading: 'Adding Note...',
				success: (data) => `${data.message}`,
				error: <b>Failed to add note</b>
			}
		);
	};

	const toggleNoteExpand = (index) => {
		setSalesOrderRow((oldData) => ({
			...oldData,
			notes: oldData?.notes?.map((note, i) =>
				index == i
					? {
							...note,
							expand: !note?.expand
					  }
					: note
			)
		}));
	};

	const handleAddToPurchaseList = (showId, items) => {
		toast.promise(
			api.createbackorder(addToPlId, items).then((res) => {
				// console.log('res', res);
				if (res.status) {
					invalidateEstQuery();
					setAddToPl(null);
					setAddToPlId(null);
				}

				return res?.status == true ? res : null;
			}),
			{
				loading: 'Adding to purchase list...',
				success: (data) => `${data.message}`,
				error: <b>Could not add to purchase list</b>
			}
		);
	};

	const removeNote = (index) => {
		toast.promise(
			api.removeEstimateNote(id, salesOrderRow?.notes?.[index]?.id).then(
				(res) => {
					if (res.status) {
						setSalesOrderRow((oldData) => ({
							...oldData,
							notes: oldData?.notes?.filter((note, i) => index != i)
						}));
					}

					return res?.status ? res : null;
				},
				() => {}
			),
			{
				loading: 'Deleting Note...',
				success: (data) => `${data.message}`,
				error: <b>Failed to delete note</b>
			}
		);
	};

	useEffect(() => {
		if (selectedSalesOrder) {
			const expDate = moment(selectedSalesOrder?.expires_at).format('YYYY-MM-DD');
			let tempRow = selectedSalesOrder?.sales_order_items?.map((item, index) => {
				return {
					id: index,
					modelId: item?.product_id,
					type: item?.product?.product_name,
					quantity: item?.qty,
					unitPrice: Number(item?.unit_price),
					total: item?.total_price
				};
			});

			tempRow = [
				...tempRow,
				{
					id: tempRow.length + 1,
					modelId: '',
					type: '',
					quantity: '',
					unitPrice: '',
					total: ''
				}
			];

			setRows(tempRow);
			setNextId(tempRow.length + 1);
			setSelectedCustomerInfo(selectedSalesOrder.customer);
			setSubtotal(selectedSalesOrder?.subtotal);

			const tempSalesOrder = {
				sales_order_number: selectedSalesOrder?.sales_order_number,
				customer_id: selectedSalesOrder?.customer_id,
				startDate: selectedSalesOrder?.start_at,
				expiryDate: expDate,
				transit_id: selectedSalesOrder?.transit_company?.id,
				sales_manager: selectedSalesOrder?.sales_person?.id,
				private_note: selectedSalesOrder?.private_note,
				vatStatus: selectedSalesOrder?.vat_type,
				vatPrice: selectedSalesOrder?.vat_percent || 21,
				currency: selectedSalesOrder?.currency,
				private_note: selectedSalesOrder?.private_note,
				expiresIn: moment(selectedSalesOrder?.expires_at).diff(selectedSalesOrder?.start_at, 'days')
			};

			let notesArray = [];

			selectedSalesOrder?.notes?.forEach((note) => {
				notesArray = [
					{
						id: note?.id,
						details: note?.details,
						date: moment(note?.created_at).format('YYYY-MM-DD'),
						time: moment(note?.created_at).format('HH:mm'),
						user_name: `${note?.user_created?.first_name} ${note?.user_created?.last_name}`,
						expand: false,
						isDeletable: note?.is_deletable
					},
					...notesArray
				];
			});

			notesArray = _.sortBy(notesArray, (n) => -n.id);

			const tempShipping = {
				unitPrice: selectedSalesOrder?.shipping_fee,
				quantity: selectedSalesOrder?.shipping_qty,
				totalPrice: Number(selectedSalesOrder?.shipping_fee) * Number(selectedSalesOrder?.shipping_qty)
			};
			setSalesOrderRow({ ...tempSalesOrder, notes: notesArray });
		}
	}, [selectedSalesOrder]);

	const calculateTotal = () => {
		let total = 0;
		let grandTotal = 0;
		for (let i = 0; i < rows.length; i++) {
			const { quantity, unitPrice } = rows[i];
			total += parseFloat(quantity || 0) * parseFloat(unitPrice || 0);
		}

		if (shippingRow?.totalPrice > 0) {
			const shippingTotal = parseFloat(shippingRow.totalPrice);
			grandTotal = total + shippingTotal;
		} else {
			grandTotal = total;
		}

		if (isNaN(grandTotal)) {
			return { subtotal: '00', modelTotal: '00' };
		}
		return { subtotal: grandTotal, modelTotal: total };
	};

	useEffect(() => {
		let tempsubtotal = calculateTotal();
		const subTotals = parseFloat(tempsubtotal?.subtotal).toFixed(2);
		const modaltotal = parseFloat(tempsubtotal?.modelTotal).toFixed(2);
		setSubtotal(subTotals);
		setModalprice(modaltotal);
	}, [rows, shippingRow, salesOrderRow]);

	useEffect(() => {
		if (salesOrderRow?.vatStatus == 'Excluding VAT' && salesOrderRow?.vatPrice > 0) {
			let tempvat = (modalPrice * (salesOrderRow?.vatPrice / 100)).toFixed(2);
			let temTotal = (parseFloat(subTotal) + parseFloat(tempvat)).toFixed(2);
			setGrandtotal(temTotal);
		} else {
			setGrandtotal(subTotal);
		}
	}, [subTotal, modalPrice, salesOrderRow]);

	const downloadSalesOrder = (template, to) => {
		api.downloadSalesOrder(id, { template, to }).then((res) => {
			// if (res?.status) {

			var newBlob = new Blob([res], { type: 'application/pdf' });
			saveAs(
				newBlob,
				`Projectmix Sales Order ${selectedSalesOrder?.sales_order_number}.pdf`
			);
		});
	};

	const handleReloadDetails = () => {
		
		invalidateEstQuery();
		handleClosePopup()
	}
	const [showAcceptPopup, setShowAcceptPopup] = useState(null);


	const approveOrder = (data) => {
		const id = data.id;
		toast.promise(
			api
				.approveEstimate({
					qoutation_id: id
				})
				.then((res) => {
					if (res.status) {
						// handleCloseConfirmationDialog();
						invalidateEstQuery();
						history(`/sales/invoices/${res?.data?.invoice_id}`);
					}
				}),
			{
				loading: 'Invoicing Estimate...',
				success: <b>Estimate has been invoiced</b>,
				error: <b>Could not process estimate.</b>
			}
		);
	};
	const cancelSalesOrder = (data) => {
		setDeleteMessage(`By canceling, this Sales Order ${ data?.sales_order_number} cannot be rolled back. Do you want to proceed?`);
		setShowCancelPopup(data);
	};
	const closeSalesOrder = (data) => {
		setDeleteMessage(`Are you sure you want to close <br />sales order ${ data?.sales_order_number}?`);
		setShowClosePopup(data);
	};


	const handleCancel = (id) => {
		toast.promise(
			api.cancelSalesOrder(id).then((res) => {
				if (res.status) {
					// handleCloseConfirmationDialog();
					invalidateEstQuery();
					history(`/sales/sales-orders`);
				}
			}),
			{
				loading: 'Canceling Sales order...',
				success: <b>Sales order cancelled</b>,
				error: <b>Could not cancel sales order.</b>
			}
		);
	}

	const handleCloseSalesOrder = (id) => {
		toast.promise(
			api.closeSalesOrder(id).then((res) => {
				if (res.status) {
					// handleCloseConfirmationDialog();
					invalidateEstQuery();
					history(`/sales/sales-orders`);
				}
			}),
			{
				loading: 'Closing Sales order...',
				success: <b>Sales order closed</b>,
				error: <b>Could not close sales order.</b>
			}
		);
	}

	const deleteSalesOrder = (data) => {
		setDeleteMessage(
			`Are you sure you want to delete <br />sales order ${ data?.sales_order_number}?`
		);
		setShowDeletePopup(data);
	};

	const handleDelete = (id) => {

		toast.promise(
			// api.deleteInvoice(id).then((res) => {
			api.deleteSalesOrder(id).then((res) => {
				if (res.status) {
					// handleCloseConfirmationDialog();
					invalidateEstQuery();
					history(`/sales/sales-orders`);
				}
			}),
			{
				loading: 'Deleting Sales Order...',
				success: <b>Sales Order deleted</b>,
				error: <b>Could not delete sales order.</b>
			}
		);
	};

	const deletePayment = (id) => { 
		setShowDeletePayment(true);
		setSelectedPaymentId(id);
	}

	const handleCreateInvoice = () => {

		toast.promise(
			// api.deleteInvoice(id).then((res) => {
			api.createInvoiceFromSalesOrder({
				sales_order_id: id
			}).then((res) => {
				if (res.status) {
					// handleCloseConfirmationDialog();
					// invalidateEstQuery();
					history(`/sales/invoices/${res?.data?.invoice_id}`);
				}
			}),
			{
				loading: 'Creating Invoice...',
				success: <b>Invoice created</b>,
				error: <b>Could not create invoice.</b>
			}
		);
	}

	return (
		<>
			<div className="row m-0 w-100 est-nv-container">
				<div className="col-12 col-md-9 p-0 p-md-2 inv-preview">
					<div>
						<Timeline 
							// key={refreshKey}
							id={id}
							source={'sales_order'}
						/>
					</div>
					<div className="section-head-new pl-0 mb-1 mt-2">




						<div className="actions" style={{ gap: '5px', width: 'auto' }}>
							{showActions && (
								<>
                                    <button
                                        type="button"
                                        className="btn btn-primary btn-outlined-main white-var"
                                        style={{ minWidth: 'unset' }}
                                        onClick={() => {
                                            openSendEmailModal(`sales_order`);
                                            setSelectedForSending(selectedSalesOrder?.id);
                                        }}
                                        disabled={isInternalLoading || !salesOrderRow?.sales_order_number}
                                    >
                                        {/* {console.log('salesOrderRow?.sales_order_number', salesOrderRow?.sales_order_number)} */}
                                        <svg
                                            id="fi_2983788"
                                            enable-background="new 0 0 512.005 512.005"
                                            height="18"
                                            viewBox="0 0 512.005 512.005"
                                            width="18"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <g>
                                                <path
                                                    d="m511.658 51.675c2.496-11.619-8.895-21.416-20.007-17.176l-482 184c-5.801 2.215-9.638 7.775-9.65 13.984-.012 6.21 3.803 11.785 9.596 14.022l135.403 52.295v164.713c0 6.948 4.771 12.986 11.531 14.593 6.715 1.597 13.717-1.598 16.865-7.843l56.001-111.128 136.664 101.423c8.313 6.17 20.262 2.246 23.287-7.669 127.599-418.357 122.083-400.163 122.31-401.214zm-118.981 52.718-234.803 167.219-101.028-39.018zm-217.677 191.852 204.668-145.757c-176.114 185.79-166.916 176.011-167.684 177.045-1.141 1.535 1.985-4.448-36.984 72.882zm191.858 127.546-120.296-89.276 217.511-229.462z"
                                                    fill="currentColor"
                                                ></path>
                                            </g>
                                        </svg>
                                        Send
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-primary btn-outlined-main white-var"
                                        style={{ minWidth: 'unset' }}
                                        disabled={isInternalLoading || !salesOrderRow?.sales_order_number}
                                        onClick={() => {
                                            // if (selectedSalesOrder?.logistic?.name) {
                                            // 	downloadEstimate('invoice', 'logistic');
                                            // } else if (selectedSalesOrder?.transit_company?.name) {
                                            if (selectedSalesOrder?.transit_company?.name) {
                                                downloadSalesOrder('invoice', 'transitcompany');
                                            } else {
                                                downloadSalesOrder('invoice', 'customer');
                                            }
                                        }}
                                    >
                                        <svg
                                            id="fi_2767144"
                                            enable-background="new 0 0 128 128"
                                            height="18"
                                            viewBox="0 0 128 128"
                                            width="18"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                id="Download"
                                                d="m128 65c0 15.439-12.563 28-28 28h-20c-2.211 0-4-1.791-4-4s1.789-4 4-4h20c11.027 0 20-8.973 20-20s-8.973-20-20-20h-4c-2.211 0-4-1.791-4-4 0-15.439-12.563-28-28-28s-28 12.561-28 28c0 2.209-1.789 4-4 4h-4c-11.027 0-20 8.973-20 20s8.973 20 20 20h20c2.211 0 4 1.791 4 4s-1.789 4-4 4h-20c-15.437 0-28-12.561-28-28s12.563-28 28-28h.223c1.996-17.975 17.277-32 35.777-32s33.781 14.025 35.777 32h.223c15.438 0 28 12.561 28 28zm-50.828 37.172-9.172 9.172v-50.344c0-2.209-1.789-4-4-4s-4 1.791-4 4v50.344l-9.172-9.172c-1.563-1.563-4.094-1.563-5.656 0s-1.563 4.094 0 5.656l16 16c.781.781 1.805 1.172 2.828 1.172s2.047-.391 2.828-1.172l16-16c1.563-1.563 1.563-4.094 0-5.656s-4.094-1.563-5.656 0z"
                                                fill="currentColor"
                                            ></path>
                                        </svg>
                                        Download
                                    </button>

									
									{
										statusAllowedToPay.includes(selectedSalesOrder?.master_status?.name) && (
											<>
												{
													selectedSalesOrder?.paid_status != 'Paid' && (
														<PermissionAllowed permissions={['Add Payment Transaction']}>
															<button
																type="button"
																className="btn btn-primary btn-outlined-main white-var m-0"
																style={{ minWidth: 'unset', margin: '0 !important' }}
																onClick={() => {
																	setShowAddPaymentModal(true);
																}}
																disabled={isInternalLoading || !salesOrderRow?.sales_order_number}
															>
																<RegisterPaymentIcon height="20px" width="20px" />
																Payment
															</button>
														</PermissionAllowed>
													)
												}
											</>
										)
                                    }
                                    {/* {
                                        !statusAllowedToDeleteDraft.includes(selectedSalesOrder?.master_status?.name) &&
                                        (
                                            <>
                                                {
                                                    !selectedSalesOrder?.is_processed && (
                                                        <>
                                                            <PermissionAllowed permissions={['Add to Purchase List (Action)']}>
    
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-primary btn-outlined-main white-var m-0"
                                                                    style={{ minWidth: 'unset', margin: '0 !important' }}
                                                                    onClick={() => {
                                                                        addToPurchaseList(selectedSalesOrder.id);
                                                                    }}
                                                                    disabled={isInternalLoading || !salesOrderRow?.sales_order_number}
    
                                                                >
                                                                    <GoChecklist style={{ fontSize: '17px' }} />
                                                                    Process Order
                                                                </button>
    
                                                            </PermissionAllowed>	
                                                        </>
                                                    )
                                                }
                                            </>
                                        )
                                    } */}
								</>
							)}
							{
								!statusNotAllowedToProcessOrder.includes(selectedSalesOrder?.master_status?.name) && (
									<>
										<PermissionAllowed permissions={['Process Sales Order']}>
										{
											(selectedSalesOrder?.allowed_to_process_order == true) && (
												<>
														<button
															type="button"
															className="btn btn-primary btn-outlined-main white-var"
															style={{ minWidth: 'unset' }}
															disabled={isInternalLoading || !salesOrderRow?.sales_order_number}
															onClick={() => {
																handleProcessOrder();
															}}
														>
															<ProcessOrderIcon width="18px" height="18px" />
															
															Process Order
														</button>
												</>
											)
										}
										</PermissionAllowed>
									</>
								)
							}
							
							{
								!statusAllowedToPayAndSalesOrder.includes(selectedSalesOrder?.master_status?.name) &&
								(
									<>
										<PermissionAllowed permissions={['Create New Estimation (Action)']}>
										{
											(!selectedSalesOrder?.invoice?.id) && (
												<>
													<button
														type="button"
														className="btn btn-primary btn-outlined-main white-var"
														style={{ minWidth: 'unset' }}
														disabled={isInternalLoading || !salesOrderRow?.sales_order_number}
														onClick={() => {
															handleCreateInvoice();
														}}
													>
														<InvoiceIcon width="18px" height="18px" />
														
														Billed
													</button>
												</>
											)
										}
										</PermissionAllowed>
									</>
								)
							}
						</div>
						<div className="actions" style={{ gap: '5px' }}>
								
							{
								selectedSalesOrder?.payments?.length < 1 && (
									<>
										<PermissionAllowed permissions={['Delete Sales Order']}>
										{
											statusAllowedToDeleteDraft.includes(selectedSalesOrder?.master_status?.name) && (
												<>
													<button
														type="button"
														className="btn btn-primary btn-outlined-danger white-var"
														style={{ minWidth: 'unset' }}
														disabled={isInternalLoading || !salesOrderRow?.sales_order_number}
														onClick={() => {
															deleteSalesOrder(selectedSalesOrder);
														}}
													>
			
														<FaRegTrashCan />
														Delete Draft
													</button>
												</>
											)
										}
										</PermissionAllowed>

										{

										(selectedSalesOrder?.master_status?.name && selectedSalesOrder?.master_status?.name !== 'Draft') && (
											<>
												{
													statusAllowedToCancel.includes(selectedSalesOrder?.master_status?.name) && (
														<>

															<PermissionAllowed permissions={['Edit Sales Order']}>
																<Dropdown className="btnDel est-btn iconbtn" align={'end'}>
																	<Dropdown.Toggle as="span">
																		<Link to={''} className="btn btn-primary btn-outlined-main white-var" style={{ minWidth: 'unset' }}>
																			Change status <LuChevronDown />
																		</Link>
																	</Dropdown.Toggle>
																	<Dropdown.Menu>
																		{
																			statusAllowedToCancel.includes(selectedSalesOrder?.master_status?.name) && (
																				<>
																					<PermissionAllowed permissions={['Cancel Sales Order']}>
																						<Dropdown.Item
																							onClick={() => {
																								cancelSalesOrder(selectedSalesOrder);
																							}}
																						>
																							Cancel
																						</Dropdown.Item>
																					</PermissionAllowed>
																				</>
																			)
																		}

																{/* {statusAllowedToApprove.includes(selectedSalesOrder?.master_status?.name) && (
																	<PermissionAllowed permissions={['Process Sales Order']}>
																		<Dropdown.Item onClick={() => approveOrder(selectedSalesOrder)}>Billed</Dropdown.Item>
																	</PermissionAllowed>
																)} */}
															</Dropdown.Menu>
														</Dropdown>
													</PermissionAllowed>
												</>
											)}
										</>
										)}
									</>
								)
							}




							<div className="est-detail-item">
                                    <StatusBadge itemStatus={selectedSalesOrder?.is_draft == 1 ? 'Draft' : selectedSalesOrder?.master_status?.name} />
							</div>
						</div>
					</div>
					<div className="row m-0">
						<div className="card shadow-sm rounded bg-white mb-3 p-3 p-xl-4 w-100">
							<SalesOrderDetailInvoice salesOrder={selectedSalesOrder} />
							<div style={{ height: '0px' }}></div>
							
							{
								selectedSalesOrder?.qoutation?.estimation_number && (
									<>
										<QoutationComponent details={selectedSalesOrder} />
									</>
								)
							}
							{
								selectedSalesOrder?.invoice?.invoice_number && (
									<>
										<InvoiceComponent details={selectedSalesOrder} />
									</>
								)
							}
							{
								selectedSalesOrder?.payments?.length > 0 && (
									<>
										<div style={{ padding: '0px 20px 20px 16px' }}>

											<div className="row mb10">
												<div className="col-12">
													<h2 className="d-flex font600" style={{ fontSize: '18px' }}>Payments</h2>
												</div>
											</div>
											
											<div className="row">
												<div className="col-md-3 font600">Date</div>
												<div className="col-md-3 font600">Type</div>
												<div className="col-md-3 font600">Amount</div>
												<div className="col-md-3 font600">Processed By</div>
											</div>
											<hr className="mt8 mb8" />
											{
												selectedSalesOrder?.payments?.map((payment, index) => (
													<>
														<div className="row hover-row">
															<div className="col-md-3">{moment(payment?.payment_date).format(CommonDateOnlyFormat)}</div>
															<div className="col-md-3">
																{
																	payment?.pay_type == 'credit-note' ? (
																		'Credit Note'
																	) : payment?.pay_type == 'bank-charges' ? (
																		'Bank Charges'
																	) : (
																		'Wired'
																	)
																}
															</div>
															<div className="col-md-3 text-success">
																{getCurrencySymbol(selectedSalesOrder?.currency)} {numberFormatter.format(payment?.amount_paid)}
															</div>
															<div className="col-md-3">
																{payment?.user_created?.first_name}
															</div>
															<div className="hover-button-container-lg mr-20 mt0">
																<div>
																	<div className="hover-button">
																		
																		{
																			payment?.attachment && (
																				<>
																					<div
																						className='btn btn-primary btn-outlined-main white-var btn-sm cursor-pointer'
																						style={{ marginLeft: '8px' }}
																						onClick={() => {
																							window.open(payment.attachment, '_blank');
																						}}
																					>
																						<div style={{ margin: 'auto 5px auto 3px' }}>
																							<FaPaperclip  />
																						</div>
																					</div>
																				</>
																			)
																		}
																		<PermissionAllowed permissions={['Delete Payment Transaction']}>
																			<div
																				className='btn btn-danger btn-outlined-danger white-var btn-sm cursor-pointer'
																				style={{ marginLeft: '8px' }}
																				onClick={() => {
																					deletePayment(payment?.id);
																				}}
																			>
																				<div>
																					<DeleteIcon2 width={'20px'} height={'20px'} />
																				</div>
																			</div>
																		</PermissionAllowed>
																	</div>
																</div>
															</div>
														</div>
														<hr className="mt8 mb8" />
													</>
												))
											}
										</div>
									</>
								)
							}

							{
								selectedSalesOrder?.purchase_order?.length > 0 && (
									<>
										<PurchaseOrderComponent details={selectedSalesOrder?.purchase_order} />
									</>
								)
							}
							{
								selectedSalesOrder?.picklists?.length > 0 && (
									<>
										<PicklistComponent details={selectedSalesOrder?.picklists} />
									</>
								)
							}

						</div>
                        {
                            orderAgreementsData?.length > 0 && (
                                <>
                                    <div className="card shadow-sm rounded bg-white mb-3 p-3 p-xl-4 w-100">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="text-center mb-3 pt-2">
                                                            <img src={agreementImage} alt="" height="70px" className="agreement-img" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <h4 className="text-center mb-3">SALES AGREEMENT</h4>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-10 offset-md-1">
                                                        <p className="text-center mb-3">
                                                            This agreement is made on <Link to="#">{moment(selectedSalesOrder?.invoiceDate).format('DD MMMM YYYY')}</Link>{' '}
                                                            between{' '}
                                                            <Link to={`/contacts/${selectedSalesOrder?.customer?.id}`} target="_blank">
                                                                <PrivacyModeContent>{selectedSalesOrder?.customer?.name}</PrivacyModeContent>
                                                            </Link>
                                                            ,{' '}
                                                            {selectedSalesOrder?.customer?.address && (
                                                                <>
                                                                    located at{' '}
                                                                    <Link to="#">
                                                                        <PrivacyModeContent>
                                                                            {selectedSalesOrder?.customer?.address ? `${selectedSalesOrder?.customer?.address}, ` : ''}
                                                                            {selectedSalesOrder?.customer?.zipcode ? `${selectedSalesOrder?.customer?.zipcode}, ` : ''}
                                                                            {selectedSalesOrder?.customer?.city ? `${selectedSalesOrder?.customer?.city}, ` : ''}
                                                                            {selectedSalesOrder?.customer?.country ? `${selectedSalesOrder?.customer?.country}` : ''}
                                                                        </PrivacyModeContent>
                                                                    </Link>{' '}
                                                                    (The Client){' '}
                                                                </>
                                                            )}
                                                            and{' '}
                                                            {selectedSalesOrder?.transit_company?.name && (
                                                                <>
                                                                    <Link to={`/contacts/${selectedSalesOrder?.transit_company?.id}`} target="_blank">
                                                                        {selectedSalesOrder?.transit_company?.name}
                                                                    </Link>{' '}
                                                                    {selectedSalesOrder?.transit_company?.address && (
                                                                        <>
                                                                            located at{' '}
                                                                            <Link to="#">
                                                                                {selectedSalesOrder?.transit_company?.address
                                                                                    ? `${selectedSalesOrder?.transit_company?.address}, `
                                                                                    : ''}
                                                                                {selectedSalesOrder?.transit_company?.zipcode
                                                                                    ? `${selectedSalesOrder?.transit_company?.zipcode}, `
                                                                                    : ''}
                                                                                {selectedSalesOrder?.transit_company?.city ? `${selectedSalesOrder?.transit_company?.city}, ` : ''}
                                                                                {selectedSalesOrder?.transit_company?.country
                                                                                    ? `${selectedSalesOrder?.transit_company?.country}`
                                                                                    : ''}
                                                                            </Link>{' '}
                                                                        </>
                                                                    )}
                                                                    (The Supplier).
                                                                </>
                                                            )}
                                                        </p>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-md-10 offset-md-1">
                                                        <p className="text-center mb-3">
                                                            Both parties confirm that they have mutually agreed upon the following conditions, as negotiated between{' '}
                                                            <b>
                                                                {selectedSalesOrder?.sales_person?.first_name} {selectedSalesOrder?.sales_person?.last_name}
                                                            </b>
                                                            , {selectedSalesOrder?.transit_company?.name && <> representing {selectedSalesOrder?.transit_company?.name}</>},{' '}
                                                            and{' '}
                                                            {selectedSalesOrder?.customer?.contact_name ? (
                                                                <>
                                                                    <Link to={`contact/${selectedSalesOrder?.customer?.id}`} target="_blank">
                                                                        <PrivacyModeContent>{selectedSalesOrder?.customer?.name}</PrivacyModeContent>
                                                                    </Link>
                                                                    , the representative of{' '}
                                                                </>
                                                            ) : (
                                                                <></>
                                                            )}{' '}
                                                            the representative of{' '}
                                                            <Link to={`/contacts/${selectedSalesOrder?.customer?.id}`} target="_blank">
                                                                <PrivacyModeContent>{selectedSalesOrder?.customer?.name}</PrivacyModeContent>
                                                            </Link>
                                                            .
                                                        </p>
                                                    </div>
                                                </div>

                                                <div className="order-agreements-detail-section">
                                                    {selectedSalesOrder?.order_agreements?.length > 0 && (
                                                        <>
                                                            {selectedSalesOrder?.order_agreements?.map((ag) => (
                                                                <div className="order-agreements-detail">
                                                                    <h6>{ag?.title}</h6>
                                                                    {ag?.type == 'multiselect' ? (
                                                                        ag?.responses?.map((res) => <p>{res?.option?.option}</p>)
                                                                    ) : (
                                                                        <p>{ag?.response?.label}</p>
                                                                    )}
                                                                </div>
                                                            ))}
                                                            <div className="row">
                                                                <div className="col-12">
                                                                    <p
                                                                        className="d-flex justify-content-center align-items-center text-center mb-4 pt-4"
                                                                        style={{ gap: '10px', color: '#838383', fontStyle: 'italic' }}
                                                                    >
                                                                        Note: <br />
                                                                        Please be aware that a 2% charge will be applied to the entire order amount if payment is not made on
                                                                        time,
                                                                        <br />
                                                                        due to fluctuations in USD and EUR currency values.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )
                        }
						{/* <div className="row" style={{ marginBottom: 10 }}>
							<div className="col-12 d-flex">
								{selectedSalesOrder?.payments?.length > 0 && (
									<>
										<div>
											<div
												className={`estimates-payment-shipment ${selectedPaymentShipment === 'payment' ? 'active' : ''}`}
												style={{ mariginLeft: 5 }}
												onClick={() => handleSelectMenu('payment')}
											>
												<svg
													id="fi_1989125"
													enable-background="new 0 0 124 124"
													height="20"
													viewBox="0 0 124 124"
													width="20"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														fill="currentColor"
														d="m118.052 26.953-88.813-15.842c-3.892-.7-7.673 2.023-8.348 5.894l-5.72 32.814h-8.171c-3.86 0-7 3.141-7 7v49.18c0 3.859 3.14 7 7 7h90.587c3.86 0 7-3.141 7-7v-17.293c2.595.463 5.1 1.009 7.499-.426 1.8-1.077 3.091-2.934 3.452-5.003l8.352-47.916c.687-3.941-1.932-7.713-5.838-8.408zm-93.22-9.261c.3-1.719 1.968-2.954 3.705-2.644l88.813 15.842c1.743.311 2.909 2.008 2.6 3.783l-1.378 7.91-95.122-16.966zm75.755 88.307c0 1.654-1.346 3-3 3h-90.587c-1.654 0-3-1.346-3-3v-49.18c0-1.654 1.346-3 3-3h90.587c1.654 0 3 1.346 3 3zm11.011-23.409c-.622 3.557-4.572 2.488-7.011 2.053v-27.824c0-3.859-3.14-7-7-7h-78.355l3.531-20.262 5.794 1.033 89.327 15.933z"
													></path>
													<path
														fill="currentColor"
														d="m47.119 101.003h-11.5c-1.104 0-2-.896-2-2s.896-2 2-2h11.5c1.104 0 2 .896 2 2s-.895 2-2 2z"
													></path>
													<path
														fill="currentColor"
														d="m25.271 101.003h-11.5c-1.104 0-2-.896-2-2s.896-2 2-2h11.5c1.104 0 2 .896 2 2s-.896 2-2 2z"
													></path>
													<path
														fill="currentColor"
														d="m68.967 101.003h-11.5c-1.104 0-2-.896-2-2s.896-2 2-2h11.5c1.104 0 2 .896 2 2s-.895 2-2 2z"
													></path>
													<path
														fill="currentColor"
														d="m90.816 101.003h-11.5c-1.104 0-2-.896-2-2s.896-2 2-2h11.5c1.104 0 2 .896 2 2s-.896 2-2 2z"
													></path>
													<path
														fill="currentColor"
														d="m30.438 90.627h-14.486c-2.757 0-5-2.243-5-5v-5.744c0-2.757 2.243-5 5-5h14.486c2.757 0 5 2.243 5 5v5.744c0 2.756-2.244 5-5 5zm-14.486-11.745c-.551 0-1 .448-1 1v5.744c0 .552.449 1 1 1h14.486c.551 0 1-.448 1-1v-5.744c0-.552-.449-1-1-1z"
													></path>
												</svg>
												<span style={{ marginLeft: 5 }}>Payments</span>
											</div>
											<div
												style={{
													marginTop: '0px',
													marginLeft: '8px',
													width: '85%',
													height: '2px',
													borderRadius: '2px',
													backgroundColor: selectedPaymentShipment === 'payment' ? '#0066FF' : 'transparent'
												}}
											></div>
										</div>
										<div>
											{selectedSalesOrder?.shipmentDetails?.length > 0 && (
												<>
													<div
														className={`estimates-payment-shipment ${selectedPaymentShipment === 'shipment' ? 'active' : ''}`}
														onClick={() => handleSelectMenu('shipment')}
													>
														<svg id="fi_5669850" height="20" viewBox="0 0 64 64" width="20" xmlns="http://www.w3.org/2000/svg">
															<g>
																<g>
																	<path
																		d="m9.1 17.4c-.9 0-1.7-.4-2.2-1l-.8-.8c-.6-.6-1-1.5-1-2.4 0-.8.4-1.6 1-2.1l1-1c.3-.3.7-.4 1-.2l5.7 2.1c.3.1.5.4.6.7s0 .7-.3.9l-2.8 2.8c-.5.7-1.3 1-2.2 1zm-1.1-5.4-.6.6c-.3.2-.4.5-.4.8s.1.7.4.9l.8.8c.5.6 1.2.5 1.7 0l1.7-1.7z"
																		fill="currentColor"
																	></path>
																</g>
																<g>
																	<path
																		d="m28.4 28.2c-.3 0-.5-.1-.7-.3l-11.2-12.7-12.2-4.6c-1-.4-1.7-1.2-2-2.2l-1.1-3.5c-.3-1.1-.1-2.2.5-3.1.8-1 2.1-1.5 3.4-1.2l37.8 11.5c.3.1.6.4.7.7s0 .7-.3.9l-14.2 14.2c-.2.2-.4.3-.7.3zm-24-25.8c-.5 0-.8.2-1.1.6s-.4.9-.3 1.3l1.1 3.5c.1.5.4.8.9 1l12.5 4.7c.1.1.3.1.4.3l10.6 12 12.3-12.3-36.2-11.1s-.1 0-.2 0z"
																		fill="currentColor"
																	></path>
																</g>
																<g>
																	<path
																		d="m17 15.4c-.3 0-.6-.1-.8-.4-.3-.4-.2-1 .2-1.4l5-3.7c.4-.3 1-.2 1.4.2.3.4.2 1-.2 1.4l-5 3.7c-.2.1-.4.2-.6.2z"
																		fill="currentColor"
																	></path>
																</g>
																<g>
																	<path
																		d="m12.6 41.3c-.1 0-.2 0-.3-.1l-5.9-2.1c-.9-.3-1.6-1.1-1.8-2.1s0-2 .7-2.8l1.4-1.4c.6-.6 1.3-.9 2.1-.9l8.5.2c.5 0 1 .5.9 1 0 .5-.4.9-1 .9l-8.5-.2c-.2 0-.5.1-.7.3l-1.4 1.4c-.2.3-.3.6-.2 1 .1.3.3.6.6.7l5.9 2.1c.5.2.8.7.6 1.2-.1.5-.5.8-.9.8z"
																		fill="currentColor"
																	></path>
																</g>
																<g>
																	<path
																		d="m38.8 38.3c-.2 0-.5-.1-.6-.2l-3-2.6c-.4-.4-.4-1-.1-1.4.4-.4 1-.4 1.4-.1l3 2.6c.4.4.4 1 .1 1.4-.3.2-.5.3-.8.3z"
																		fill="currentColor"
																	></path>
																</g>
																<g>
																	<path
																		d="m55.1 38.2c-.4 0-.8-.3-.9-.7l-5.1-16.8c-.2-.5.1-1.1.6-1.2.5-.2 1.1.1 1.2.6l5.1 16.8c.2.5-.1 1.1-.6 1.2-.1.1-.2.1-.3.1z"
																		fill="currentColor"
																	></path>
																</g>
																<g>
																	<path
																		d="m26.7 58.5c-.2 0-.4 0-.6-.1-1-.2-1.8-.9-2.1-1.8l-2.1-5.9c-.2-.5.1-1.1.6-1.2.5-.2 1.1.1 1.2.6l2.1 5.9c.1.3.4.5.7.6.2 0 .6.1.9-.3l1.3-1.5c0-.1.1-.1.2-.1.1-.1.2-.3.2-.6l-.2-8.5c0-.5.4-1 .9-1s1 .4 1 .9l.2 8.5c0 .7-.2 1.6-.9 2.1l-1.2 1.4c-.7.7-1.4 1-2.2 1z"
																		fill="currentColor"
																	></path>
																</g>
																<g>
																	<path
																		d="m14.9 50.4c-.7 0-1.2-.2-1.6-.6-.9-.9-1.4-2.7 2.5-9 2-3.4 4.7-6.9 5.8-8.1l28.6-28.7c1.6-1.6 6-4 9.1-4 1.2 0 2.1.3 2.7 1 1.2 1.2 1.3 3.4.2 6.2-.8 2.3-2 4.5-3.3 5.6l-28.6 28.7c-1.7 1.7-11.5 8.9-15.4 8.9zm-.3-2h.3c3 0 12.1-6.4 14-8.3l28.7-28.7c1-.9 2.1-2.9 2.8-4.8s.8-3.6.2-4.2c-.3-.4-.9-.4-1.3-.4-2.5 0-6.3 2-7.7 3.4l-28.7 28.7c-2.8 2.8-9.1 12.8-8.3 14.3z"
																		fill="currentColor"
																	></path>
																</g>
																<g>
																	<path
																		d="m57.5 9.3c-.2 0-.4 0-.5-.1-.5-.3-.6-.9-.3-1.3s.1-.9-.2-1.2-.8-.4-1.2-.2c-.5.3-1.1.1-1.3-.3-.3-.5-.1-1.1.3-1.3 1.1-.7 2.6-.5 3.6.4 1 1 1.1 2.5.4 3.6-.2.2-.5.4-.8.4z"
																		fill="currentColor"
																	></path>
																</g>
																<g>
																	<path
																		d="m11.3 52.8c-.2 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4l10.6-10.6c.4-.4 1-.4 1.4 0s.4 1 0 1.4l-10.6 10.6c-.2.2-.5.3-.7.3z"
																		fill="currentColor"
																	></path>
																</g>
																<g>
																	<path
																		d="m58.2 64h-18.5c-2.7 0-4.9-2.2-4.9-4.9v-14.1c0-2.7 2.2-4.9 4.9-4.9h18.5c2.7 0 4.9 2.2 4.9 4.9v14.2c-.2 2.7-2.3 4.8-4.9 4.8zm-18.5-21.9c-1.6 0-2.9 1.3-2.9 2.9v14.1c0 1.6 1.3 2.9 2.9 2.9h18.5c1.6 0 2.9-1.3 2.9-2.9v-14.2c0-1.6-1.3-2.9-2.9-2.9h-18.5z"
																		fill="currentColor"
																	></path>
																</g>
																<g>
																	<path
																		d="m52.3 50.1c-.2 0-.5-.1-.6-.2l-2.8-2.4-2.7 2.3c-.3.3-.7.3-1 .2-.3-.2-.6-.5-.6-.9v-5.1c0-.5.4-1 1-1s1 .4 1 1v3l1.7-1.5c.4-.3.9-.3 1.3 0l1.8 1.5v-3c0-.5.4-1 1-1s1 .4 1 1v5.1c0 .4-.2.7-.6.9-.2 0-.4.1-.5.1z"
																		fill="currentColor"
																	></path>
																</g>
																<g>
																	<g>
																		<path
																			fill="currentColor"
																			d="m58.1 57.1h-4.7c-.5 0-1-.4-1-1s.4-1 1-1h4.7c.5 0 1 .4 1 1s-.5 1-1 1z"
																		></path>
																	</g>
																	<g>
																		<path
																			fill="currentColor"
																			d="m58.1 60.1h-4.7c-.5 0-1-.4-1-1s.4-1 1-1h4.7c.5 0 1 .4 1 1s-.5 1-1 1z"
																		></path>
																	</g>
																</g>
															</g>
														</svg>
														<span style={{ marginLeft: 5 }}>Shipments</span>
													</div>
													<div
														style={{
															marginTop: '0px',
															marginLeft: '8px',
															width: '85%',
															height: '2px',
															borderRadius: '2px',
															backgroundColor: selectedPaymentShipment === 'shipment' ? '#0066FF' : 'transparent'
														}}
													></div>
												</>
											)}
										</div>
									</>
								)}
							</div>
						</div> */}
						{selectedPaymentShipment === 'payment' && (
							// <div
							// 	className="card shadow-sm rounded bg-white mb-3 p-1 w-100"
							// 	style={{ display: paymentData?.length > 0 ? 'block' : 'none' }}
							// >
								<PaymentsComponent
									paymentData={paymentData}
									setPaymentData={setPaymentData}
									estimateDetails={selectedSalesOrder}
									onAddPayment={handleAddPayment}
									onUpdatePayment={handleUpdatePayment}
									onDeletePayment={handleDeletePayment}
									setShowAddPayment={setShowAddPaymentModal}
									showAddPayment={showAddPaymentModal}
									toggleExpand={handlePaymentToggle}
									showOnlyModal={true}
									source={'sales_order'}
								/>
							// </div>
						)}
						{selectedPaymentShipment === 'shipment' && (
							<div
								className="card shadow-sm rounded bg-white mb-3 p-1 w-100"
								style={{ display: shipmentData?.length > 0 ? 'block' : 'none' }}
							>
								<ShipmentsComponent shipmentData={shipmentData} setShipmentData={setShipmentData} estimateDetails={selectedSalesOrder} />
							</div>
						)}
					</div>
				</div>
				<div className="col-12 col-md-3 inv-details">
					<div className="marginTop65">
						{/* <div className="est-detail-item">
							<label htmlFor="inputName" className="est-labels">
								Status
							</label>

							<p>{selectedSalesOrder?.master_status?.name}</p>
						</div> */}
						<div className="est-detail-item">
							<label htmlFor="inputName" className="est-labels">
								Currency & Rate
							</label>
{/* 
							{
								selectedSalesOrder?.source_rate === 'live' ? (
									<> */}
										<p>
											{selectedSalesOrder?.currency?.toUpperCase()}, 1 ={' '}
											{
												selectedSalesOrder?.currency == 'eur' ? `$ ${((1 / selectedSalesOrder?.currency_rate?.currency_rates[1]?.rate) * selectedSalesOrder?.currency_rate?.currency_rates[2]?.rate).toFixed(4)}` : 
												selectedSalesOrder?.currency == 'usd' ? `€ ${((1 / selectedSalesOrder?.currency_rate?.currency_rates[2]?.rate) * selectedSalesOrder?.currency_rate?.currency_rates[1]?.rate).toFixed(4)}` : 
												selectedSalesOrder?.currency == 'aed' ? `€ ${((1 / selectedSalesOrder?.currency_rate?.currency_rates[0]?.rate) * selectedSalesOrder?.currency_rate?.currency_rates[1]?.rate).toFixed(4)}` : ''
											}
										</p>
									{/* </>
								) : (
									<>
										<p>
											{
												selectedSalesOrder?.currency_rate?.currency_rates.map((rate) => {
													if (rate?.converted_currency.toUpperCase() === selectedSalesOrder?.currency?.toUpperCase()) {
														return `${rate?.converted_currency?.toUpperCase()}, ${rate?.converted_symbol} 1 = `
													}
												})
											}
											{
												selectedSalesOrder?.currency?.toUpperCase() === selectedCurrency?.toUpperCase() ? (
													<>
														{
															selectedSalesOrder?.currency_rate?.currency_rates.map((rate) => {
																if (rate?.converted_currency.toUpperCase() === selectedSalesOrder?.currency?.toUpperCase()) {
																	return `${rate?.converted_currency?.toUpperCase()} 1`
																}
															})
														}
													</>
												) : (
													<>
														{
															
															selectedSalesOrder?.currency_rate?.currency_rates.map((rate) => {
																// if (rate?.origin_currency?.toUpperCase() === selectedSalesOrder?.currency?.toUpperCase() && rate?.converted_currency.toUpperCase() === selectedCurrency?.toUpperCase()) {
																if (rate?.origin_currency?.toUpperCase() === selectedSalesOrder?.currency?.toUpperCase()) {
																	if (rate?.origin_currency?.toUpperCase() === selectedSalesOrder?.currency?.toUpperCase() && rate?.converted_currency.toUpperCase() === selectedCurrency?.toUpperCase()) {
																		return `${rate?.converted_currency} ${rate?.rate}`
																	}
																}
															})
														}
													</>
												)
											}
										</p>
									</>
								)
							} */}
						</div>
						{
							selectedSalesOrder?.transit_company?.name ? (
								<>
									<div className="est-detail-item">
										<label htmlFor="inputName" className="est-labels">
											Transit Company
										</label>

										<Link to={`/contacts/${selectedSalesOrder?.transit_company?.id}`}>
											<PrivacyModeContent>{selectedSalesOrder?.transit_company?.name}</PrivacyModeContent>
										</Link>
									</div>
								</>
							) : (
								<>
									<div className="est-detail-item">
										<label htmlFor="inputName" className="est-labels">
											Customer
										</label>

										<Link to={`/contacts/${selectedSalesOrder?.customer?.id}`}>
											<PrivacyModeContent>{selectedSalesOrder?.customer?.name}</PrivacyModeContent>
										</Link>
									</div>
								</>
							)
						}
						<div className="est-detail-item">
							<label htmlFor="inputName" className="est-labels">
								Sales Manager
							</label>

							<Link to={`/settings/user/${selectedSalesOrder?.sales_person?.id}`}>
								{selectedSalesOrder?.sales_person?.first_name} {selectedSalesOrder?.sales_person?.last_name}
							</Link>
						</div>
						{
							selectedSalesOrder?.master_status?.name == 'Open' && (
								<>
									<div className="mt20">
										<TimelineStep
											selectedSalesOrder={selectedSalesOrder}
											startDateParam={selectedSalesOrder?.start_at}
											endDateParam={selectedSalesOrder?.expires_at}
										/>
									</div>
								</>
							)
						}
						{/* <div className="est-detail-item">
							<label htmlFor="inputName" className="est-labels">
								Status
							</label>

						</div>
						<div className="est-detail-item">
							<TimelineStep
								statusParam={selectedSalesOrder?.master_status?.name}
								startDateParam={selectedSalesOrder?.invoice_date}
								endDateParam={selectedSalesOrder?.invoice_due_date}
							/>
						</div> */}
						{/* <div className="">
							<div class="timeline timeline--warning" data-controller="timeline-component">
								<p class="timeline__text">0</p>
								<div class="timeline__step" style={{ flexGrow: '15' }}></div>
								<p class="timeline__text">15</p>
								<div class="timeline__step" style={{ flexGrow: '11' }}></div>
								<p class="timeline__text">26</p>
								<div class="timeline__step" style={{ flexGrow: '6' }}></div>
								<p class="timeline__text">32</p>
								<div class="timeline__step" style={{ flexGrow: '6' }}></div>
								<p class="timeline__text">38</p>
								<span class="timeline__active-line timeline__active-line" style={{ width: '21%' }}>
									<span class="timeline__active-dot"></span>
								</span>
							</div>
						</div> */}
						{/* {selectedSalesOrder?.master_status?.name != 'Credit Note' && (
							<div className="order-agreements-detail-section">
								{selectedSalesOrder?.order_agreements?.length > 0 && (
									<>
										{selectedSalesOrder?.order_agreements?.map((ag) => (
											<div className="order-agreements-detail">
												<h6>{ag?.title}</h6>
												{ag?.responses?.map((res) => (
													<p>{res?.option?.option}</p>
												))}
											</div>
										))}
									</>
								)}
							</div>
						)} */}

						<Notes
							notes={salesOrderRow?.notes}
							addNote={addNote}
							deleteNotePermission={[]}
							removeNote={removeNote}
							toggleNoteExpand={toggleNoteExpand}
							parentId={1}
							type="estimate"
						/>
					</div>
				</div>
				{confirmationDialog?.show && (
					<ActionConfirmationModal
						show={confirmationDialog?.show}
						message={confirmationDialog?.message}
						title={confirmationDialog?.title}
						customConfirmText={confirmationDialog?.customConfirm || 'Confirm'}
						customCancelText={confirmationDialog?.customCancel || 'Cancel'}
						customIcon={<PiInfoFill />}
						handleConfirm={confirmationDialog?.handleConfirm}
						handleClose={confirmationDialog?.handleClose}
					/>
				)}

				{openSendEmailPopup && (
					<SendEmailModal
						openPopup={openSendEmailPopup}
						selectedEstimate={selectedSalesOrder}
						handleClose={closeSendEmailPopup}
						sendEstimateEmail={handleSendEmail}
						sendingEstimate={sendingSalesOrder}
						selectedForSending={selectedForSending}
						disableStatus={isDisable}
					/>
				)}

				{showDeletePopup && (
					<DeleteConfirmation
						show={showDeletePopup}
						handleClose={handleClosePopup}
						handleDelete={handleDelete}
						title="Confirm"
						message={`${deleteMessage}`}
					/>
				)}

				{showCancelPopup && (
					<DeleteConfirmation
						show={showCancelPopup}
						handleClose={handleClosePopup}
						handleDelete={handleCancel}
						title="Are you sure?"
						message={`${deleteMessage}`}
						// customDeleteButtonText="Confirm"
						customDeleteButtonText={`Yes, Cancel this Sales Order`}
					/>
				)}
				{showClosePopup && (
					<DeleteConfirmation
						show={showClosePopup}
						handleClose={handleCloseSalesOrder}
						handleDelete={handleCloseSalesOrder}
						title="Confirm"
						message={`${deleteMessage}`}
						customDeleteButtonText="Confirm"
					/>
				)}

				{/* {showAWBModal && <AWBModal handleClose={() => closeAWBModal()} selectedSalesOrder={selectedSalesOrder} />} */}

				<AddToPurchaseListConfirmation
					show={addToPl}
					handleClose={handlePlDialogClose}
					handleConfirm={(showId, items) => handleAddToPurchaseList(showId, items)}
				/>



				{showProcessOrderModal && (
					<ProcessOrderModal
						openPopup={showProcessOrderModal}
						collection={selectedSalesOrder}
						handleClose={handleClosePopup}
						handleReloadDetails={handleReloadDetails}
						handleProcessPurchaseOrder={handleCreatePurchaseOrder}
						handleSendToOperation={handleCreatePurchaseOrder}
					/>
				)}

				{showPurchaseOrderModal && (
					<PurchaseOrderModal
						openPopup={showPurchaseOrderModal}
						selectedSalesOrder={selectedSalesOrder}
						handleClose={closePurchaseOrderPopup}
						handleItems={purchaseOrderItems}
						handleCreatePurchaseOrder={handleCreatePurchaseOrder}
						
					/>
				)}
				{
					showDeletePayment && (
						<>
							<DeleteConfirmation
								key="paymentDelete"
								show={showDeletePayment}
								handleClose={handleClosePopup}
								handleDelete={handleDeletePayment}
								title="Confirm"
								message={`Are you sure you want to delete this payment?`}
							/>
						</>
					)
				}
			</div>
		</>
	);
};

export default SalesOrderDetails;
