import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Collapse } from 'react-bootstrap';
import { HiOutlineDeviceMobile, HiOutlineDocumentReport } from 'react-icons/hi';

import Logo from 'assets/images/logo.png';
import LogoBlack from 'assets/images/logo_black.png';
import LogoWhite from 'assets/images/logo_white.png';
import LogoSingle from 'assets/images/pmix-logo-single.svg';
import barcodeScanner from 'assets/images/barcode-scanner.svg';
import Logo1 from 'assets/images/project-mix-icon.png';
import { useDispatch, useSelector } from 'react-redux';
import QuickActions from 'ui-component/quick-actions/QuickActions';
import CurrencyDropdown from 'ui-component/currency-dropdown/CurrencyDropdown';
import {
	BackOrdersIcon,
	ContactsIcons,
	CustomersIcon,
	DashboardIcon,
	InboundIcon,
	MastersIcon,
	ModelsIcon,
	OperationsIcon,
	OrdersIcon,
	OutboundIcon,
	PicklistIcon,
	ProductsIcon,
	PurchaseListIcon,
	ReturnsIcon,
	RolesIcon,
	SerialsIcon,
	SettingsIcon,
	ShipmentIcon,
	UsersIcon,
	SalesOrderIcon,
	InvoiceIcon,
	QuotationIcon
} from 'ui-component/custom-icons/icons';
import { BsClipboardCheck } from 'react-icons/bs';
import { GiHandTruck } from 'react-icons/gi';
import { div } from '@mui/icons-material';
import PermissionAllowed from 'ui-component/permissions/PermissionAllowed';
import { TbFileInvoice, TbHomeStats, TbReportAnalytics, TbReportMoney } from 'react-icons/tb';
import { IoStatsChartOutline } from 'react-icons/io5';
import { ImStatsBars2 } from 'react-icons/im';
import { LuContact2, LuMonitorSmartphone } from 'react-icons/lu';
import { RiBillLine, RiBuildingLine } from 'react-icons/ri';
import usePermissions from 'hooks/usePermissions';

import { FaChevronLeft, FaChevronRight, FaRegClock, FaChevronDown, FaChevronUp } from 'react-icons/fa6';

// ==============================|| SIDEBAR DRAWER ||============================== //

const Sidebar = ({ mobile, mobileShowMenu, closeMobileMenu }) => {
	const history = useNavigate();
	const { pathname } = useLocation();
	const [show, setShow] = useState('');
	const [isOpened, setIsOpened] = useState(true);
	const currentUserRole = useSelector((state) => state.main.user?.role?.[0]?.name);
	const [displayTheme] = useSelector((state) => [state.main.displayTheme]);
	const compRef = useRef(null);
	const [showSalesSubmenu, setShowSalesSubmenu] = useState(false);
	const [allPermissions, hasEveryPermission] = usePermissions();
	const [logoInDarkMode, setLogoInDarkMode] = useState(false);
	const dispatch = useDispatch();

	useEffect(() => {
		
		// if (pathname.includes('/manage')) {
		// 	setShow('manage');
		// }
		// // else if (pathname.includes('/productmanagement/')) {
		// // 	setShow('productmanagement');
		// // }
		// else if (pathname.includes('/sales/invoices/create')) {
		// 	setShow('estimation');
		// } else if (pathname.includes('/sales/invoices')) {
		// 	setShow('estimation');
		// } else if (pathname.includes('/settings')) {
		// 	setShow('settings');
		// } else if (pathname.includes('/purchaseall')) {
		// 	setShow('purchaseall');
		// } else if (pathname.includes('/operations')) {
		// 	setShow('operations');
		// } else if (pathname.includes('/inventory')) {
		// 	setShow('products');
		// } else if (pathname.includes('/contacts')) {
		// 	setShow('contacts');
		// } else {
		// 	setShow('');
		// }

		// if (!isOpened) {
		setShow('');
		// }
	}, [pathname, isOpened]);

	useEffect(() => {
		if (window.innerWidth <= 1150 && isOpened) {
			setIsOpened(false);
		}

		console.log('mobileShowMenu');
		
	}, []);

	useEffect(() => {

		const getDarkMode = localStorage.getItem('displayTheme');
		if (!getDarkMode) {
		} else {
			if (getDarkMode === 'lightMode') {
				setLogoInDarkMode(false);
			} else {
				setLogoInDarkMode(true);
			}
		}
	}, [displayTheme]);

	useEffect(() => {
		if (show == '' && mobile) {
			closeMobileMenu && closeMobileMenu();
		}
	}, [show, mobile]);

	const handleResize = () => {
		if (window.innerWidth <= 1150 && isOpened) {
			setIsOpened(false);
		} else if (window.innerWidth > 1150) {
			setIsOpened(true);
		}
	};

	useEffect(() => {
		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	const handleClickOutside = (event) => {
		if (compRef.current && !compRef.current.contains(event.target)) {
			setShow('');
		}
	};

	useEffect(() => {
		document.addEventListener('click', handleClickOutside, true);

		if (pathname.includes('/sales/invoices') || pathname.includes('/sales/quotations') || pathname.includes('/sales/sales-orders')) {
			setShowSalesSubmenu(true);
		}

		return () => {
			document.removeEventListener('click', handleClickOutside, true);
		};
	}, []);

	return (
		<>
			{show && <div className="nav-opacity-bg">&nbsp;</div>}
			<div
				className={`new-nav-bar ${mobile ? 'mobile-menu' : ''} ${isOpened || mobile ? 'expanded' : 'collapsed'} ${mobileShowMenu ? 'show-mobile' : ''
					}`}
			>
				{!mobile && (
					<div className="nav-brand-logo">
						<Link className="sidebar-brand d-flex align-items-center justify-content-center" to={'/dashboard'}>
							{/* <div className="sidebar-brand-icon" onClick={() => history('/dashboard')}>
						<img src={Logo1} alt="logo1" className="img-fluid" width={45} />
					</div> */}
							<div className="sidebar-brand-text" onClick={() => history('/dashboard')}>
								{isOpened ? (
								
									// <img src={LogoBlack} alt="logo" className="img-fluid" width={150} />
									<img src={logoInDarkMode ? LogoWhite : LogoBlack} alt="logo" className="img-fluid" width={150} />
								) : (
									<img src={LogoSingle} alt="logo" className="img-fluid" width={40} />
								)}
							</div>
						</Link>
						{/* <li className="quick-action">
							<QuickActions isOpened={isOpened} />
						</li> */}
					</div>
				)}

				<ul className="navbar-nav-items" ref={compRef}>
					{/* Sidebar - Brand */}

					{/* Divider */}
					{/* <hr className="sidebar-divider my-3" /> */}
					{/* Nav Item - Dashboard */}

					{/* <li className="mb-1 mainLabel nav-title">&nbsp;</li> */}
					<li className={`nav-item main-menu mt-2 ${pathname === '/dashboard' ? 'active' : ''}`}>
						<Link
							to={'/dashboard'}
							className="nav-link"
							onClick={() => {
								mobile && closeMobileMenu && closeMobileMenu();
							}}
						>
							<span className="menuIcon">
								<DashboardIcon width="14" height="14" />
							</span>
							<span className="menuLabel"><div>Dashboard</div></span>
						</Link>
					</li>

					<li className="nav-break">&nbsp;</li>

					<PermissionAllowed permissions={['List Estimation (Page)']}>
						<li className={`nav-item sub-menu ${pathname.includes('/sales/invoices') || pathname.includes('/sales/quotations') || pathname.includes('/sales/sales-orders') ? 'active' : ''}`}>
							<Link
								className={`nav-link collapsed `}
								to="#"
								data-toggle="collapse"
								data-target="#collapseTwo"
								aria-expanded="true"
								aria-controls="collapseTwo"
								// onClick={() => setShow(show === 'sales' ? '' : 'sales')}
								onClick={() => {
									setShowSalesSubmenu(!showSalesSubmenu);
								}}
							>
								<span className="menuIcon">
									<OrdersIcon width="14" height="14" />
								</span>
								<span className="menuLabel">

									<div>Sales</div>
									<span style={{ position: 'absolute', right: '30px', marginTop: '-20px' }}>
										{showSalesSubmenu ? <FaChevronUp /> : <FaChevronDown />}
										{/* , FaChevronUp */}
									</span>
								</span>

							</Link>
							{showSalesSubmenu && (
								<ul className={`sidebar-collapsable-menu ${showSalesSubmenu ? 'show' : ''}`}>
										
									<li className={`nav-item ${pathname.includes('/sales/quotations') ? 'text-active' : 'text-inactive'}`}>
										<Link to={'/sales/quotations'} className={`nav-link collapsed`}>
											<span className="menuIcon">
											<QuotationIcon height='16px' width='16px' />
											</span>
											<span className={pathname.includes('/sales/quotations') ? 'text-active submenuLabel' : 'text-inactive submenuLabel'}>
												<div>Quotations</div>
											</span>
										</Link>
										
										
										<PermissionAllowed permissions={['Create Estimation (Button)']}>
											<div className="menu-quick-create"
												style={{ position: 'absolute', right: '20px' }}
												onClick={() => {
														history('/sales/quotations/create')
												}}
											>
								
												<div className="passive">
													<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
														<g id="Group_40246" data-name="Group 40246" transform="translate(-568 11561)">
															<g id="more" transform="translate(568 -11561)">
															<g id="_x32_">
																<path id="Path_1969" data-name="Path 1969" d="M12,18a1,1,0,0,1-1-1V7a1,1,0,0,1,2,0V17A1,1,0,0,1,12,18Z" fill="currentColor" />
															</g>
															<g id="_x31_">
																<path id="Path_1970" data-name="Path 1970" d="M6,12a1,1,0,0,1,1-1H17a1,1,0,0,1,0,2H7A1,1,0,0,1,6,12Z" fill="currentColor" />
															</g>
															</g>
															<g id="Rectangle_644" data-name="Rectangle 644" transform="translate(568 -11561)" fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="2" stroke-dasharray="4 3">
															<rect width="24" height="24" rx="5" stroke="none"/>
															<rect x="1" y="1" width="22" height="22" rx="4" fill="none"/>
															</g>
														</g>
													</svg>
												</div>
												<div className="active">
													<svg clip-rule="evenodd" width="16" height="16" fill-rule="evenodd" image-rendering="optimizeQuality" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" viewBox="0 0 2.54 2.54" xmlns="http://www.w3.org/2000/svg">
														<g id="&#x56FE;&#x5C42;_x0020_1">
															<path d="m.37253 0h1.79494c.20518 0 .37253.16735.37253.37253v1.79494c0 .20518-.16735.37253-.37253.37253h-1.79494c-.20518 0-.37253-.16735-.37253-.37253v-1.79494c0-.20518.16735-.37253.37253-.37253z" fill="#48b02c" />
															<g id="_493074688" fill="#fff">
																<path id="_493075912" d="m.49106 1.14116h1.55788c.0373 0 .06773.03043.06773.06773v.12222c0 .0373-.03043.06773-.06773.06773h-1.55788c-.0373 0-.06773-.03043-.06773-.06773v-.12222c0-.0373.03043-.06773.06773-.06773z" />
																<path id="_493075216" d="m1.14116 2.04894v-1.55788c0-.0373.03043-.06773.06773-.06773h.12222c.0373 0 .06773.03043.06773.06773v1.55788c0 .0373-.03043.06773-.06773.06773h-.12222c-.0373 0-.06773-.03043-.06773-.06773z" />
															</g>
														</g>
													</svg>
												</div>
											</div>
										</PermissionAllowed>
									</li> 
										 
									<PermissionAllowed permissions={['List Sales Orders']}>										
										<li className={`nav-item ${pathname.includes('/sales/sales-orders') ? 'text-active' : 'text-inactive'}`}>
											<Link to={'/sales/sales-orders'} className={`nav-link collapsed`}>
												<span className="menuIcon">
													<SalesOrderIcon height='18px' width='18px' />
												</span>
												<span className={pathname.includes('/sales/sales-orders') ? 'text-active submenuLabel' : 'text-inactive submenuLabel'}><div>Sales Orders</div></span>
											</Link>
											
											<PermissionAllowed permissions={['Create Sales Order']}>
												<div className="menu-quick-create"
													style={{ position: 'absolute', right: '20px' }}
													onClick={() => {
															history('/sales/sales-orders/create')
													}}
												>
													
													<div className="passive">
														<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
															<g id="Group_40246" data-name="Group 40246" transform="translate(-568 11561)">
																<g id="more" transform="translate(568 -11561)">
																<g id="_x32_">
																	<path id="Path_1969" data-name="Path 1969" d="M12,18a1,1,0,0,1-1-1V7a1,1,0,0,1,2,0V17A1,1,0,0,1,12,18Z" fill="currentColor" />
																</g>
																<g id="_x31_">
																	<path id="Path_1970" data-name="Path 1970" d="M6,12a1,1,0,0,1,1-1H17a1,1,0,0,1,0,2H7A1,1,0,0,1,6,12Z" fill="currentColor" />
																</g>
																</g>
																<g id="Rectangle_644" data-name="Rectangle 644" transform="translate(568 -11561)" fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="2" stroke-dasharray="4 3">
																<rect width="24" height="24" rx="5" stroke="none"/>
																<rect x="1" y="1" width="22" height="22" rx="4" fill="none"/>
																</g>
															</g>
														</svg>
													</div>
													<div className="active">
														<svg clip-rule="evenodd" width="16" height="16" fill-rule="evenodd" image-rendering="optimizeQuality" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" viewBox="0 0 2.54 2.54" xmlns="http://www.w3.org/2000/svg">
															<g id="&#x56FE;&#x5C42;_x0020_1">
																<path d="m.37253 0h1.79494c.20518 0 .37253.16735.37253.37253v1.79494c0 .20518-.16735.37253-.37253.37253h-1.79494c-.20518 0-.37253-.16735-.37253-.37253v-1.79494c0-.20518.16735-.37253.37253-.37253z" fill="#48b02c" />
																<g id="_493074688" fill="#fff">
																	<path id="_493075912" d="m.49106 1.14116h1.55788c.0373 0 .06773.03043.06773.06773v.12222c0 .0373-.03043.06773-.06773.06773h-1.55788c-.0373 0-.06773-.03043-.06773-.06773v-.12222c0-.0373.03043-.06773.06773-.06773z" />
																	<path id="_493075216" d="m1.14116 2.04894v-1.55788c0-.0373.03043-.06773.06773-.06773h.12222c.0373 0 .06773.03043.06773.06773v1.55788c0 .0373-.03043.06773-.06773.06773h-.12222c-.0373 0-.06773-.03043-.06773-.06773z" />
																</g>
															</g>
														</svg>
													</div>

												</div>
											</PermissionAllowed>
										</li>
									</PermissionAllowed>

									<li className={`nav-item ${pathname.includes('/sales/invoices') ? 'text-active' : 'text-inactive'}`}>
										<Link to={'/sales/invoices'} className={`nav-link collapsed`}>
											<span className="menuIcon">
												<InvoiceIcon height='16px' width='16px' />
											</span>
											<span className={pathname.includes('/sales/invoices') ? 'text-active submenuLabel' : 'text-inactive submenuLabel'}><div>Invoices</div></span>
										</Link>
										
										<PermissionAllowed permissions={['Create Estimation (Button)']}>
											<div className="menu-quick-create"
												style={{ position: 'absolute', right: '20px' }}
												onClick={() => {
														history('/sales/invoices/create')
												}}
											>
												
												<div className="passive">
													<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
														<g id="Group_40246" data-name="Group 40246" transform="translate(-568 11561)">
															<g id="more" transform="translate(568 -11561)">
															<g id="_x32_">
																<path id="Path_1969" data-name="Path 1969" d="M12,18a1,1,0,0,1-1-1V7a1,1,0,0,1,2,0V17A1,1,0,0,1,12,18Z" fill="currentColor" />
															</g>
															<g id="_x31_">
																<path id="Path_1970" data-name="Path 1970" d="M6,12a1,1,0,0,1,1-1H17a1,1,0,0,1,0,2H7A1,1,0,0,1,6,12Z" fill="currentColor" />
															</g>
															</g>
															<g id="Rectangle_644" data-name="Rectangle 644" transform="translate(568 -11561)" fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="2" stroke-dasharray="4 3">
															<rect width="24" height="24" rx="5" stroke="none"/>
															<rect x="1" y="1" width="22" height="22" rx="4" fill="none"/>
															</g>
														</g>
													</svg>
												</div>
												<div className="active">
													<svg clip-rule="evenodd" width="16" height="16" fill-rule="evenodd" image-rendering="optimizeQuality" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" viewBox="0 0 2.54 2.54" xmlns="http://www.w3.org/2000/svg">
														<g id="&#x56FE;&#x5C42;_x0020_1">
															<path d="m.37253 0h1.79494c.20518 0 .37253.16735.37253.37253v1.79494c0 .20518-.16735.37253-.37253.37253h-1.79494c-.20518 0-.37253-.16735-.37253-.37253v-1.79494c0-.20518.16735-.37253.37253-.37253z" fill="#48b02c" />
															<g id="_493074688" fill="#fff">
																<path id="_493075912" d="m.49106 1.14116h1.55788c.0373 0 .06773.03043.06773.06773v.12222c0 .0373-.03043.06773-.06773.06773h-1.55788c-.0373 0-.06773-.03043-.06773-.06773v-.12222c0-.0373.03043-.06773.06773-.06773z" />
																<path id="_493075216" d="m1.14116 2.04894v-1.55788c0-.0373.03043-.06773.06773-.06773h.12222c.0373 0 .06773.03043.06773.06773v1.55788c0 .0373-.03043.06773-.06773.06773h-.12222c-.0373 0-.06773-.03043-.06773-.06773z" />
															</g>
														</g>
													</svg>
												</div>

											</div>
										</PermissionAllowed>
									</li>

									
								</ul>
							)}
						</li>
					</PermissionAllowed>
					{/* <PermissionAllowed permissions={['List Estimation (Page)']}>
						<li className={`nav-item ${pathname.includes('/sales') ? 'active' : ''}`}>
							<Link
								className={`nav-link collapsed`}
								to="#"
								data-toggle="collapse"
								data-target="#collapseTwo"
								aria-expanded="true"
								aria-controls="collapseTwo"
								onClick={() => setShow(show === 'sales' ? '' : 'sales')}
							>
								<span className="menuIcon">
									<OrdersIcon width="14" height="14" />
								</span>
								<span>Sales</span>
							</Link>
							<Collapse in={show === 'sales'}>
								<div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
									<div className=" collapse-inner rounded">
										<Link to={'/sales/estimates'} className={`collapse-item ${pathname.includes('/estimates') ? 'active' : ''}`}>
											<span className="menuIcon">
												<OperationsIcon width="16" height="16" />
											</span>
											<span>Estimates</span>
										</Link>
										<Link to={'/sales/invoices'} className={`collapse-item ${pathname.includes('/invoices') ? 'active' : ''}`}>
											<span className="menuIcon">
												<OperationsIcon width="16" height="16" />
											</span>
											<span>Invoices</span>
										</Link>
									</div>
								</div>
							</Collapse>
						</li>
					</PermissionAllowed> */}

					{/* Overview */}
					{/* <PermissionAllowed permissions={['List Estimation (Page)']}>
						<li className={`nav-item ${pathname.includes('/sales/invoices') ? 'active' : ''}`}>
							<Link
								to={'/sales/invoices'}
								className="nav-link"
								onClick={() => {
									mobile && closeMobileMenu && closeMobileMenu();
								}}
							>
								<span className="menuIcon">
									<OrdersIcon width="14" height="14" />
								</span>
								<span>Orders</span>
							</Link>
						</li>
					</PermissionAllowed> */}

					{/* <PermissionAllowed permissions={['List Picklists (Page)']}>
						<li
							className={`nav-item main-menu ${pathname.includes('/coming-soon') ? 'active' : ''}`}
							onClick={() => {
								setShowSalesSubmenu(false);
							}}
						>
							<Link to={'/coming-soon'} className={`nav-link`}>
								<span className="menuIcon">
									<svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" width="16" height="16" data-name="Layer 1" viewBox="0 0 24 24">
										<path
											d="m19,1H5C2.243,1,0,3.243,0,6v12c0,2.757,2.243,5,5,5h14c2.757,0,5-2.243,5-5V6c0-2.757-2.243-5-5-5Zm3,17c0,1.654-1.346,3-3,3H5c-1.654,0-3-1.346-3-3V6c0-1.654,1.346-3,3-3h14c1.654,0,3,1.346,3,3v12Zm-3-11c0,.552-.448,1-1,1h-7c-.552,0-1-.448-1-1s.448-1,1-1h7c.552,0,1,.448,1,1Zm-11,0c0,.828-.672,1.5-1.5,1.5s-1.5-.672-1.5-1.5.672-1.5,1.5-1.5,1.5.672,1.5,1.5Zm11,5c0,.552-.448,1-1,1h-7c-.552,0-1-.448-1-1s.448-1,1-1h7c.552,0,1,.448,1,1Zm-11,0c0,.828-.672,1.5-1.5,1.5s-1.5-.672-1.5-1.5.672-1.5,1.5-1.5,1.5.672,1.5,1.5Zm11,5c0,.552-.448,1-1,1h-7c-.552,0-1-.448-1-1s.448-1,1-1h7c.552,0,1,.448,1,1Zm-11,0c0,.828-.672,1.5-1.5,1.5s-1.5-.672-1.5-1.5.672-1.5,1.5-1.5,1.5.672,1.5,1.5Z"
											fill="currentColor"
										/>
									</svg>
								</span>
								<span className="menuLabel"><div>Picklists</div></span>
							</Link>
						</li>
					</PermissionAllowed> */}
					
					<PermissionAllowed permissions={['List Picklists (Page)']}>
						<li className={`nav-item main-menu ${pathname.includes('/picklists') ? 'active' : ''}`}>
							<Link
								to={'/picklists'}
								className="nav-link"
								onClick={() => {
									mobile && closeMobileMenu && closeMobileMenu();
									setShowSalesSubmenu(false);
								}}
							>
								<span className="menuIcon">
									<svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" width="16" height="16" data-name="Layer 1" viewBox="0 0 24 24">
										<path
											d="m19,1H5C2.243,1,0,3.243,0,6v12c0,2.757,2.243,5,5,5h14c2.757,0,5-2.243,5-5V6c0-2.757-2.243-5-5-5Zm3,17c0,1.654-1.346,3-3,3H5c-1.654,0-3-1.346-3-3V6c0-1.654,1.346-3,3-3h14c1.654,0,3,1.346,3,3v12Zm-3-11c0,.552-.448,1-1,1h-7c-.552,0-1-.448-1-1s.448-1,1-1h7c.552,0,1,.448,1,1Zm-11,0c0,.828-.672,1.5-1.5,1.5s-1.5-.672-1.5-1.5.672-1.5,1.5-1.5,1.5.672,1.5,1.5Zm11,5c0,.552-.448,1-1,1h-7c-.552,0-1-.448-1-1s.448-1,1-1h7c.552,0,1,.448,1,1Zm-11,0c0,.828-.672,1.5-1.5,1.5s-1.5-.672-1.5-1.5.672-1.5,1.5-1.5,1.5.672,1.5,1.5Zm11,5c0,.552-.448,1-1,1h-7c-.552,0-1-.448-1-1s.448-1,1-1h7c.552,0,1,.448,1,1Zm-11,0c0,.828-.672,1.5-1.5,1.5s-1.5-.672-1.5-1.5.672-1.5,1.5-1.5,1.5.672,1.5,1.5Z"
											fill="currentColor"
										/>
									</svg>
								</span>
								<span className="menuLabel">
									<div className="sidebar-title-backside"></div><div>Picklists</div>
								</span>
							</Link>
						</li>
					</PermissionAllowed>
					{/* 
					<li className={`nav-item ${pathname.includes('/picklist') ? 'active' : ''}`}>
						<Link
							to={'/coming-soon'}
							className="nav-link"
							onClick={() => {
								mobile && closeMobileMenu && closeMobileMenu();
							}}
						>
							<span className="menuIcon">
								<PicklistIcon width="16" height="16" />
							</span>
							<span>Picklist</span>
						</Link>
					</li> */}

					<PermissionAllowed permissions={['List Estimation (Page)']}>
						<li className={`nav-item main-menu ${pathname.includes('/backorders') ? 'active' : ''}`}>
							<Link
								to={'/backorders'}
								className="nav-link"
								onClick={() => {
									mobile && closeMobileMenu && closeMobileMenu();
									setShowSalesSubmenu(false);
								}}
							>
								<span className="menuIcon">
									<FaRegClock width="14" height="14" />
								</span>
								<span className="menuLabel">
									<div className="sidebar-title-backside"></div><div>Backorders</div>
								</span>
							</Link>
						</li>
					</PermissionAllowed>

					{/* Purchase List */}
					<PermissionAllowed permissions={['Display Purchase List (Page)']}>
						<li
							// className={`nav-item main-menu ${pathname.includes('/purchaseall') || pathname.includes('/purchaseorder') || pathname.includes('/purchase-invoice')
							className={`nav-item main-menu ${pathname.includes('/purchasing')
								? 'active'
								: ''
								}`}
						>
							<Link
								to={'/purchasing'}
								className="nav-link purchaseList"
								onClick={() => {
									mobile && closeMobileMenu && closeMobileMenu();
									setShowSalesSubmenu(false);
								}}
							>
								<span className="menuIcon">
									<PurchaseListIcon width="16" height="16" />
								</span>
								<span className="menuLabel">
									<div className="sidebar-title-backside"></div><div>Purchasing</div>
								</span>
							</Link>
										
							<PermissionAllowed permissions={['Create Purchase Order']}>
								<div className="menu-quick-create"
									onClick={() => {
										history('/purchasing/create')
									}}
								>
									
									<div className="passive">
										<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
											<g id="Group_40246" data-name="Group 40246" transform="translate(-568 11561)">
												<g id="more" transform="translate(568 -11561)">
												<g id="_x32_">
													<path id="Path_1969" data-name="Path 1969" d="M12,18a1,1,0,0,1-1-1V7a1,1,0,0,1,2,0V17A1,1,0,0,1,12,18Z" fill="currentColor" />
												</g>
												<g id="_x31_">
													<path id="Path_1970" data-name="Path 1970" d="M6,12a1,1,0,0,1,1-1H17a1,1,0,0,1,0,2H7A1,1,0,0,1,6,12Z" fill="currentColor" />
												</g>
												</g>
												<g id="Rectangle_644" data-name="Rectangle 644" transform="translate(568 -11561)" fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="2" stroke-dasharray="4 3">
												<rect width="24" height="24" rx="5" stroke="none"/>
												<rect x="1" y="1" width="22" height="22" rx="4" fill="none"/>
												</g>
											</g>
										</svg>
									</div>
									<div className="active">
										<svg clip-rule="evenodd" width="16" height="16" fill-rule="evenodd" image-rendering="optimizeQuality" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" viewBox="0 0 2.54 2.54" xmlns="http://www.w3.org/2000/svg">
											<g id="&#x56FE;&#x5C42;_x0020_1">
												<path d="m.37253 0h1.79494c.20518 0 .37253.16735.37253.37253v1.79494c0 .20518-.16735.37253-.37253.37253h-1.79494c-.20518 0-.37253-.16735-.37253-.37253v-1.79494c0-.20518.16735-.37253.37253-.37253z" fill="#48b02c" />
												<g id="_493074688" fill="#fff">
													<path id="_493075912" d="m.49106 1.14116h1.55788c.0373 0 .06773.03043.06773.06773v.12222c0 .0373-.03043.06773-.06773.06773h-1.55788c-.0373 0-.06773-.03043-.06773-.06773v-.12222c0-.0373.03043-.06773.06773-.06773z" />
													<path id="_493075216" d="m1.14116 2.04894v-1.55788c0-.0373.03043-.06773.06773-.06773h.12222c.0373 0 .06773.03043.06773.06773v1.55788c0 .0373-.03043.06773-.06773.06773h-.12222c-.0373 0-.06773-.03043-.06773-.06773z" />
												</g>
											</g>
										</svg>
									</div>

								</div>
							</PermissionAllowed>
						</li>
					</PermissionAllowed>
					{/* <PermissionAllowed permissions={['Display Purchase List (Page)']}>
						<li
							className={`nav-item ${pathname.includes('/purchasing') || pathname.includes('/purchaseorder') || pathname.includes('/purchase-invoice')
								? 'active'
								: ''
								}`}
						>
							<Link
								to={'/purchasing'}
								className="nav-link purchaseList"
								onClick={() => {
									mobile && closeMobileMenu && closeMobileMenu();
									setShowSalesSubmenu(false);
								}}
							>
								<span className="menuIcon">
									<PurchaseListIcon width="16" height="16" />
								</span>
								<span>New Purchasing</span>
							</Link>
						</li>
					</PermissionAllowed> */}
					{/* <PermissionAllowed permissions={['Display Purchase List (Page)']}>
						<li className={`nav-item ${pathname === '/purchaseorder' ? 'active' : ''}`}>
							<Link
								to={'/purchaseorder'}
								className="nav-link purchaseList"
								onClick={() => {
									mobile && closeMobileMenu && closeMobileMenu();
								}}
							>
								<span className="menuIcon">
									<PurchaseListIcon width="16" height="16" />
								</span>
								<span>Purchase Order</span>
							</Link>
						</li>
					</PermissionAllowed> */}

					{/* Purchase List */}

					<li className="nav-break">&nbsp;</li>

					<PermissionAllowed permissions={['List Returns (Page)']}>
						<li
							className={`nav-item main-menu ${pathname.includes('/returns') ? 'active' : ''}`}
							onClick={() => {
								setShowSalesSubmenu(false);
							}}
						>
							<Link to={'/returns'} className={`nav-link`}>
								<span className="menuIcon">
									<svg fill="none" viewBox="0 0 24 24" width="18" height="18" xmlns="http://www.w3.org/2000/svg">
										<path
											clip-rule="evenodd"
											d="m12.4097 3.08776c-.3593-.16132-.7797-.09679-1.0741.16483l-8.99996 8.00001c-.21349.1898-.33564.4618-.33564.7474s.12215.5576.33564.7474l8.99996 8c.2944.2616.7148.3262 1.0741.1648.3592-.1613.5903-.5184.5903-.9122v-3.9551c2.4541.221 4.7767 1.2505 6.5944 2.9418l.7244.674c.2913.271.7156.3436 1.0805.1847.3648-.1589.6007-.5189.6007-.9168v-.9286c0-5.1853-3.9467-9.4489-9-9.95062v-4.04938c0-.39379-.2311-.75092-.5903-.91224zm-1.4097 3.13909v2.77315c0 .55229.4477 1 1 1 3.9492 0 7.2301 2.8616 7.8822 6.6244-2.2646-1.6961-5.0279-2.6244-7.8822-2.6244-.5523 0-1 .4477-1 1v2.7732l-6.4948-5.7732z"
											fill="currentColor"
											fill-rule="evenodd"
										></path>
									</svg>
								</span>
								<span className="menuLabel">
									<div className="sidebar-title-backside"></div><div>Returns</div>
								</span>
							</Link>
										
							<PermissionAllowed permissions={['Create Returns (Button)']}>
								<div className="menu-quick-create"
									onClick={() => {
											history('/returns/create')
									}}
								>
									
									<div className="passive">
										<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
											<g id="Group_40246" data-name="Group 40246" transform="translate(-568 11561)">
												<g id="more" transform="translate(568 -11561)">
												<g id="_x32_">
													<path id="Path_1969" data-name="Path 1969" d="M12,18a1,1,0,0,1-1-1V7a1,1,0,0,1,2,0V17A1,1,0,0,1,12,18Z" fill="currentColor" />
												</g>
												<g id="_x31_">
													<path id="Path_1970" data-name="Path 1970" d="M6,12a1,1,0,0,1,1-1H17a1,1,0,0,1,0,2H7A1,1,0,0,1,6,12Z" fill="currentColor" />
												</g>
												</g>
												<g id="Rectangle_644" data-name="Rectangle 644" transform="translate(568 -11561)" fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="2" stroke-dasharray="4 3">
												<rect width="24" height="24" rx="5" stroke="none"/>
												<rect x="1" y="1" width="22" height="22" rx="4" fill="none"/>
												</g>
											</g>
										</svg>
									</div>
									<div className="active">
										<svg clip-rule="evenodd" width="16" height="16" fill-rule="evenodd" image-rendering="optimizeQuality" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" viewBox="0 0 2.54 2.54" xmlns="http://www.w3.org/2000/svg">
											<g id="&#x56FE;&#x5C42;_x0020_1">
												<path d="m.37253 0h1.79494c.20518 0 .37253.16735.37253.37253v1.79494c0 .20518-.16735.37253-.37253.37253h-1.79494c-.20518 0-.37253-.16735-.37253-.37253v-1.79494c0-.20518.16735-.37253.37253-.37253z" fill="#48b02c" />
												<g id="_493074688" fill="#fff">
													<path id="_493075912" d="m.49106 1.14116h1.55788c.0373 0 .06773.03043.06773.06773v.12222c0 .0373-.03043.06773-.06773.06773h-1.55788c-.0373 0-.06773-.03043-.06773-.06773v-.12222c0-.0373.03043-.06773.06773-.06773z" />
													<path id="_493075216" d="m1.14116 2.04894v-1.55788c0-.0373.03043-.06773.06773-.06773h.12222c.0373 0 .06773.03043.06773.06773v1.55788c0 .0373-.03043.06773-.06773.06773h-.12222c-.0373 0-.06773-.03043-.06773-.06773z" />
												</g>
											</g>
										</svg>
									</div>

								</div>
							</PermissionAllowed>
						</li>
					</PermissionAllowed>


					{/* <PermissionAllowed permissions={['List Returns (Page)']}>
						<li
							className={`nav-item main-menu ${pathname.includes('/operations') ? 'active' : ''}`}
							onClick={() => {
								setShowSalesSubmenu(false);
							}}
						>
							<Link to={'/operations'} className={`nav-link`}>
								<span className="menuIcon">
									<OperationsIcon width="16" height="16" />
								</span>
								<span className="menuLabel">
									<div style={{ display: 'flex' }}>New Operation</div>
								</span>
							</Link>
										
							<PermissionAllowed permissions={['Create Returns (Button)']}>
								<div className="menu-quick-create"
									onClick={() => {
											history('/operations')
									}}
								>
									<div className="passive">
										<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
											<g id="Group_40246" data-name="Group 40246" transform="translate(-568 11561)">
												<g id="more" transform="translate(568 -11561)">
												<g id="_x32_">
													<path id="Path_1969" data-name="Path 1969" d="M12,18a1,1,0,0,1-1-1V7a1,1,0,0,1,2,0V17A1,1,0,0,1,12,18Z" fill="currentColor" />
												</g>
												<g id="_x31_">
													<path id="Path_1970" data-name="Path 1970" d="M6,12a1,1,0,0,1,1-1H17a1,1,0,0,1,0,2H7A1,1,0,0,1,6,12Z" fill="currentColor" />
												</g>
												</g>
												<g id="Rectangle_644" data-name="Rectangle 644" transform="translate(568 -11561)" fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="2" stroke-dasharray="4 3">
												<rect width="24" height="24" rx="5" stroke="none"/>
												<rect x="1" y="1" width="22" height="22" rx="4" fill="none"/>
												</g>
											</g>
										</svg>
									</div>
									<div className="active">
										<svg clip-rule="evenodd" width="16" height="16" fill-rule="evenodd" image-rendering="optimizeQuality" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" viewBox="0 0 2.54 2.54" xmlns="http://www.w3.org/2000/svg">
											<g id="&#x56FE;&#x5C42;_x0020_1">
												<path d="m.37253 0h1.79494c.20518 0 .37253.16735.37253.37253v1.79494c0 .20518-.16735.37253-.37253.37253h-1.79494c-.20518 0-.37253-.16735-.37253-.37253v-1.79494c0-.20518.16735-.37253.37253-.37253z" fill="#48b02c" />
												<g id="_493074688" fill="#fff">
													<path id="_493075912" d="m.49106 1.14116h1.55788c.0373 0 .06773.03043.06773.06773v.12222c0 .0373-.03043.06773-.06773.06773h-1.55788c-.0373 0-.06773-.03043-.06773-.06773v-.12222c0-.0373.03043-.06773.06773-.06773z" />
													<path id="_493075216" d="m1.14116 2.04894v-1.55788c0-.0373.03043-.06773.06773-.06773h.12222c.0373 0 .06773.03043.06773.06773v1.55788c0 .0373-.03043.06773-.06773.06773h-.12222c-.0373 0-.06773-.03043-.06773-.06773z" />
												</g>
											</g>
										</svg>
									</div>
									
								</div>
							</PermissionAllowed>
						</li>
					</PermissionAllowed> */}

					<PermissionAllowed permissions={['List Operations']}>
						<li
							className={`nav-item main-menu ${pathname.includes('/operations') ? 'active' : ''}`}
							onClick={() => {
								setShowSalesSubmenu(false);
							}}
						>
							<Link to={'/operations'} className={`nav-link`}>
								<span className="menuIcon">
									<OperationsIcon width="16" height="16" />	
								</span>
								<span className="menuLabel">
									<div className="sidebar-title-backside"></div><div>Operations</div>
								</span>
							</Link>
										
							<PermissionAllowed permissions={['Create Operations']}>
								<div className="menu-quick-create"
									onClick={() => {
											history('/operations/create')
									}}
								>
									
									<div className="passive">
										<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
											<g id="Group_40246" data-name="Group 40246" transform="translate(-568 11561)">
												<g id="more" transform="translate(568 -11561)">
												<g id="_x32_">
													<path id="Path_1969" data-name="Path 1969" d="M12,18a1,1,0,0,1-1-1V7a1,1,0,0,1,2,0V17A1,1,0,0,1,12,18Z" fill="currentColor" />
												</g>
												<g id="_x31_">
													<path id="Path_1970" data-name="Path 1970" d="M6,12a1,1,0,0,1,1-1H17a1,1,0,0,1,0,2H7A1,1,0,0,1,6,12Z" fill="currentColor" />
												</g>
												</g>
												<g id="Rectangle_644" data-name="Rectangle 644" transform="translate(568 -11561)" fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="2" stroke-dasharray="4 3">
												<rect width="24" height="24" rx="5" stroke="none"/>
												<rect x="1" y="1" width="22" height="22" rx="4" fill="none"/>
												</g>
											</g>
										</svg>
									</div>
									<div className="active">
										<svg clip-rule="evenodd" width="16" height="16" fill-rule="evenodd" image-rendering="optimizeQuality" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" viewBox="0 0 2.54 2.54" xmlns="http://www.w3.org/2000/svg">
											<g id="&#x56FE;&#x5C42;_x0020_1">
												<path d="m.37253 0h1.79494c.20518 0 .37253.16735.37253.37253v1.79494c0 .20518-.16735.37253-.37253.37253h-1.79494c-.20518 0-.37253-.16735-.37253-.37253v-1.79494c0-.20518.16735-.37253.37253-.37253z" fill="#48b02c" />
												<g id="_493074688" fill="#fff">
													<path id="_493075912" d="m.49106 1.14116h1.55788c.0373 0 .06773.03043.06773.06773v.12222c0 .0373-.03043.06773-.06773.06773h-1.55788c-.0373 0-.06773-.03043-.06773-.06773v-.12222c0-.0373.03043-.06773.06773-.06773z" />
													<path id="_493075216" d="m1.14116 2.04894v-1.55788c0-.0373.03043-.06773.06773-.06773h.12222c.0373 0 .06773.03043.06773.06773v1.55788c0 .0373-.03043.06773-.06773.06773h-.12222c-.0373 0-.06773-.03043-.06773-.06773z" />
												</g>
											</g>
										</svg>
									</div>

								</div>
							</PermissionAllowed>
						</li>
					</PermissionAllowed>
					
					
					{/* <PermissionAllowed
						permissions={[
							'List Task Inspection (Page)',
							'List Task Collection (Page)',
							'List Task Segregation (Page)',
							'List Task Testing (Page)',
							'List Task Inbounding (Page)',
							'List Task Quality Control (Page)',
							'List Task Repairing (Page)',
							'List Task Retesting (Page)',
							'List Task Packing (Page)',
							'List Transit Shipment (Page)'
						]}
						hasSome={true}
					>
						<li className={`nav-item main-menu ${pathname.includes('/operations') ? 'active' : ''}`}>
							<Link
								className={`nav-link collapsed`}
								to="#"
								data-toggle="collapse"
								data-target="#collapseTwo"
								aria-expanded="true"
								aria-controls="collapseTwo"
								onClick={() => {
									setShow(show === 'operations' ? '' : 'operations');
									setShowSalesSubmenu(false);
								}}
							>
								<span className="menuIcon">
									<OperationsIcon width="16" height="16" />
								</span>
								<span className="menuLabel">
									<div style={{ display: 'flex' }}>
										Operations
										
										<PermissionAllowed permissions={['Create Operations (Button)']}>
											<div className="menu-quick-create"
												style={{ position: 'absolute', right: '20px' }}
												onClick={() => {
														history('/operations/create')
												}}
											>
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
													<g id="Group_40246" data-name="Group 40246" transform="translate(-568 11561)">
														<g id="more" transform="translate(568 -11561)">
														<g id="_x32_">
															<path id="Path_1969" data-name="Path 1969" d="M12,18a1,1,0,0,1-1-1V7a1,1,0,0,1,2,0V17A1,1,0,0,1,12,18Z" fill="currentColor" />
														</g>
														<g id="_x31_">
															<path id="Path_1970" data-name="Path 1970" d="M6,12a1,1,0,0,1,1-1H17a1,1,0,0,1,0,2H7A1,1,0,0,1,6,12Z" fill="currentColor" />
														</g>
														</g>
														<g id="Rectangle_644" data-name="Rectangle 644" transform="translate(568 -11561)" fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="2" stroke-dasharray="4 3">
														<rect width="24" height="24" rx="5" stroke="none"/>
														<rect x="1" y="1" width="22" height="22" rx="4" fill="none"/>
														</g>
													</g>
												</svg>

											</div>
										</PermissionAllowed>

									</div>
								</span>
							</Link>
							<Collapse in={show === 'operations'}>
								<div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
									<div className=" collapse-inner rounded">
										<PermissionAllowed permissions={['List Task Inspection (Page)']}>
											<Link
												to={'/operations/inspection'}
												className={`collapse-item ${pathname.includes('/inspection') ? 'active' : ''}`}
												onClick={() => {
													setShowSalesSubmenu(false);
												}}
											>
												<span className="menuIcon">
													<OperationsIcon width="16" height="16" />
												</span>
												<span>Inspections</span>
											</Link>
										</PermissionAllowed>
										<PermissionAllowed permissions={['List Task Collection (Page)']}>
											<Link
												to={'/operations/collection'}
												className={`collapse-item ${pathname.includes('/collection') ? 'active' : ''}`}
												onClick={() => {
													setShowSalesSubmenu(false);
												}}
											>
												<span className="menuIcon">
													<OperationsIcon width="16" height="16" />
												</span>
												<span>Collections</span>
											</Link>
										</PermissionAllowed>
										<PermissionAllowed permissions={['List Task Segregation (Page)']}>
											<Link
												to={'/operations/segregation'}
												className={`collapse-item ${pathname.includes('/segregation') ? 'active' : ''}`}
												onClick={() => {
													setShowSalesSubmenu(false);
												}}
											>
												<span className="menuIcon">
													<OperationsIcon width="16" height="16" />
												</span>
												<span>Segregation</span>
											</Link>
										</PermissionAllowed>
										<PermissionAllowed permissions={['List Task Testing (Page)']}>
											<Link
												to={'/operations/testing'}
												className={`collapse-item ${pathname.includes('/testing') ? 'active' : ''}`}
												onClick={() => {
													setShowSalesSubmenu(false);
												}}
											>
												<span className="menuIcon">
													<OperationsIcon width="16" height="16" />
												</span>
												<span>Testing</span>
											</Link>
										</PermissionAllowed>
										<PermissionAllowed permissions={['List Task Inbounding (Page)']}>
											<Link
												to={'/operations/inbounding'}
												className={`collapse-item ${pathname.includes('/inbounding') ? 'active' : ''}`}
												onClick={() => {
													setShowSalesSubmenu(false);
												}}
											>
												<span className="menuIcon">
													<OperationsIcon width="16" height="16" />
												</span>
												<span>Inbounding</span>
											</Link>
										</PermissionAllowed>
										<PermissionAllowed permissions={['List Task Quality Control (Page)']}>
											<Link
												to={'/operations/quality-control'}
												className={`collapse-item ${pathname.includes('/quality-control') ? 'active' : ''}`}
												onClick={() => {
													setShowSalesSubmenu(false);
												}}
											>
												<span className="menuIcon">
													<OperationsIcon width="16" height="16" />
												</span>
												<span>Quality Control</span>
											</Link>
										</PermissionAllowed>
										<PermissionAllowed permissions={['List Task Repairing (Page)']}>
											<Link
												to={'/operations/repairing'}
												className={`collapse-item ${pathname.includes('/repairing') ? 'active' : ''}`}
												onClick={() => {
													setShowSalesSubmenu(false);
												}}
											>
												<span className="menuIcon">
													<OperationsIcon width="16" height="16" />
												</span>
												<span>Repairing</span>
											</Link>
										</PermissionAllowed>
										<PermissionAllowed permissions={['List Task Retesting (Page)']}>
											<Link
												to={'/operations/retesting'}
												className={`collapse-item ${pathname.includes('/retesting') ? 'active' : ''}`}
												onClick={() => {
													setShowSalesSubmenu(false);
												}}
											>
												<span className="menuIcon">
													<OperationsIcon width="16" height="16" />
												</span>
												<span>Retesting</span>
											</Link>
										</PermissionAllowed>
										<PermissionAllowed permissions={['List Task Packing (Page)']}>
											<Link
												to={'/operations/packing'}
												className={`collapse-item ${pathname.includes('/packing') ? 'active' : ''}`}
												onClick={() => {
													setShowSalesSubmenu(false);
												}}
											>
												<span className="menuIcon">
													<OperationsIcon width="16" height="16" />
												</span>
												<span>Packing</span>
											</Link>
										</PermissionAllowed>
										<PermissionAllowed permissions={['List Transit Shipment (Page)']}>
											<Link
												to={'/operations/transitshipments'}
												className={`collapse-item ${pathname.includes('/transitshipments') ? 'active' : ''}`}
												onClick={() => {
													setShowSalesSubmenu(false);
												}}
											>
												<span className="menuIcon">
													<OperationsIcon width="16" height="16" />
												</span>
												<span>Transit</span>
											</Link>
										</PermissionAllowed>
									</div>
								</div>
							</Collapse>
						</li>
					</PermissionAllowed> */}

					<PermissionAllowed permissions={['List Shipment (Page)']}>
						<li className={`nav-item main-menu ${pathname.includes('/shipments') ? 'active' : ''}`}>
							<Link
								to={'/shipments'}
								className={`nav-link`}
								onClick={() => {
									mobile && closeMobileMenu && closeMobileMenu();
									setShowSalesSubmenu(false);
								}}
							>
								<span className="menuIcon">
									<ShipmentIcon width="16" height="16" />
									{/* <LiaShippingFastSolid style={{fontSize: '1.3em'}} /> */}
								</span>
								<span className="menuLabel">
									<div className="sidebar-title-backside"></div><div>Shipments</div>
								</span>
							</Link>
						</li>
					</PermissionAllowed>

					{/* Purchase List */}
					<PermissionAllowed permissions={['List Contact (Page)']}>
						<li className={`nav-item main-menu ${pathname.includes('/contacts') ? 'active' : ''}`}>
							<Link
								to={'/contacts'}
								className="nav-link purchaseList"
								onClick={() => {
									mobile && closeMobileMenu && closeMobileMenu();
									setShowSalesSubmenu(false);
								}}
							>
								<span className="menuIcon">
									<ContactsIcons width="16" height="16" />
								</span>
								<span className="menuLabel">
									<div className="sidebar-title-backside"></div><div>Contacts</div>
								</span>
							</Link>
							
							<PermissionAllowed permissions={['Create Contact (Button)']}>
								<div className="menu-quick-create"
									onClick={() => {
											history('/contacts/create')
									}}
								>
									
									<div className="passive">
										<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
											<g id="Group_40246" data-name="Group 40246" transform="translate(-568 11561)">
												<g id="more" transform="translate(568 -11561)">
												<g id="_x32_">
													<path id="Path_1969" data-name="Path 1969" d="M12,18a1,1,0,0,1-1-1V7a1,1,0,0,1,2,0V17A1,1,0,0,1,12,18Z" fill="currentColor" />
												</g>
												<g id="_x31_">
													<path id="Path_1970" data-name="Path 1970" d="M6,12a1,1,0,0,1,1-1H17a1,1,0,0,1,0,2H7A1,1,0,0,1,6,12Z" fill="currentColor" />
												</g>
												</g>
												<g id="Rectangle_644" data-name="Rectangle 644" transform="translate(568 -11561)" fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="2" stroke-dasharray="4 3">
												<rect width="24" height="24" rx="5" stroke="none"/>
												<rect x="1" y="1" width="22" height="22" rx="4" fill="none"/>
												</g>
											</g>
										</svg>
									</div>
									<div className="active">
										<svg clip-rule="evenodd" width="16" height="16" fill-rule="evenodd" image-rendering="optimizeQuality" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" viewBox="0 0 2.54 2.54" xmlns="http://www.w3.org/2000/svg">
											<g id="&#x56FE;&#x5C42;_x0020_1">
												<path d="m.37253 0h1.79494c.20518 0 .37253.16735.37253.37253v1.79494c0 .20518-.16735.37253-.37253.37253h-1.79494c-.20518 0-.37253-.16735-.37253-.37253v-1.79494c0-.20518.16735-.37253.37253-.37253z" fill="#48b02c" />
												<g id="_493074688" fill="#fff">
													<path id="_493075912" d="m.49106 1.14116h1.55788c.0373 0 .06773.03043.06773.06773v.12222c0 .0373-.03043.06773-.06773.06773h-1.55788c-.0373 0-.06773-.03043-.06773-.06773v-.12222c0-.0373.03043-.06773.06773-.06773z" />
													<path id="_493075216" d="m1.14116 2.04894v-1.55788c0-.0373.03043-.06773.06773-.06773h.12222c.0373 0 .06773.03043.06773.06773v1.55788c0 .0373-.03043.06773-.06773.06773h-.12222c-.0373 0-.06773-.03043-.06773-.06773z" />
												</g>
											</g>
										</svg>
									</div>

								</div>
							</PermissionAllowed>
						</li>
					</PermissionAllowed>

					<li className="nav-break">&nbsp;</li>

					<PermissionAllowed permissions={['List Warehouse (Page)']}>
						<li className={`nav-item main-menu ${pathname.includes('/warehouses') ? 'active' : ''}`}>
							<Link
								to={'/warehouses'}
								className={`nav-link`}
								onClick={() => {
									setShowSalesSubmenu(false);
								}}
							>
								<span className="menuIcon">
									<RiBuildingLine style={{ height: '17px', width: '17px' }} />
								</span>
								<span className="menuLabel">
									<div className="sidebar-title-backside"></div><div>Warehouse</div>
								</span>
							</Link>
										
							<PermissionAllowed permissions={['Create Warehouse (Button)']}>
								<div className="menu-quick-create"
									style={{ position: 'absolute', right: '20px' }}
									onClick={() => {
											history('/warehouses/create')
									}}
								>
									
									<div className="passive">
										<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
											<g id="Group_40246" data-name="Group 40246" transform="translate(-568 11561)">
												<g id="more" transform="translate(568 -11561)">
												<g id="_x32_">
													<path id="Path_1969" data-name="Path 1969" d="M12,18a1,1,0,0,1-1-1V7a1,1,0,0,1,2,0V17A1,1,0,0,1,12,18Z" fill="currentColor" />
												</g>
												<g id="_x31_">
													<path id="Path_1970" data-name="Path 1970" d="M6,12a1,1,0,0,1,1-1H17a1,1,0,0,1,0,2H7A1,1,0,0,1,6,12Z" fill="currentColor" />
												</g>
												</g>
												<g id="Rectangle_644" data-name="Rectangle 644" transform="translate(568 -11561)" fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="2" stroke-dasharray="4 3">
												<rect width="24" height="24" rx="5" stroke="none"/>
												<rect x="1" y="1" width="22" height="22" rx="4" fill="none"/>
												</g>
											</g>
										</svg>
									</div>
									<div className="active">
										<svg clip-rule="evenodd" width="16" height="16" fill-rule="evenodd" image-rendering="optimizeQuality" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" viewBox="0 0 2.54 2.54" xmlns="http://www.w3.org/2000/svg">
											<g id="&#x56FE;&#x5C42;_x0020_1">
												<path d="m.37253 0h1.79494c.20518 0 .37253.16735.37253.37253v1.79494c0 .20518-.16735.37253-.37253.37253h-1.79494c-.20518 0-.37253-.16735-.37253-.37253v-1.79494c0-.20518.16735-.37253.37253-.37253z" fill="#48b02c" />
												<g id="_493074688" fill="#fff">
													<path id="_493075912" d="m.49106 1.14116h1.55788c.0373 0 .06773.03043.06773.06773v.12222c0 .0373-.03043.06773-.06773.06773h-1.55788c-.0373 0-.06773-.03043-.06773-.06773v-.12222c0-.0373.03043-.06773.06773-.06773z" />
													<path id="_493075216" d="m1.14116 2.04894v-1.55788c0-.0373.03043-.06773.06773-.06773h.12222c.0373 0 .06773.03043.06773.06773v1.55788c0 .0373-.03043.06773-.06773.06773h-.12222c-.0373 0-.06773-.03043-.06773-.06773z" />
												</g>
											</g>
										</svg>
									</div>

								</div>
							</PermissionAllowed>
						</li>
					</PermissionAllowed>

					<PermissionAllowed permissions={['List Product (Page)']}>
						<li className={`nav-item main-menu ${pathname.includes('/products') ? 'active' : ''}`}>
							<Link
								to={'/products'}
								className={`nav-link`}
								onClick={() => {
									setShowSalesSubmenu(false);
								}}
							>
								<span className="menuIcon">
									<HiOutlineDeviceMobile style={{ fontSize: '1.3em' }} />
								</span>
								<span className="menuLabel">
									<div className="sidebar-title-backside"></div><div>Products</div>
								</span>
							</Link>
										
							<PermissionAllowed permissions={['Create Model (Button)']}>
								<div className="menu-quick-create"
									style={{ position: 'absolute', right: '20px' }}
									onClick={() => {
											history('/products/create')
									}}
								>
									
									<div className="passive">
										<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
											<g id="Group_40246" data-name="Group 40246" transform="translate(-568 11561)">
												<g id="more" transform="translate(568 -11561)">
												<g id="_x32_">
													<path id="Path_1969" data-name="Path 1969" d="M12,18a1,1,0,0,1-1-1V7a1,1,0,0,1,2,0V17A1,1,0,0,1,12,18Z" fill="currentColor" />
												</g>
												<g id="_x31_">
													<path id="Path_1970" data-name="Path 1970" d="M6,12a1,1,0,0,1,1-1H17a1,1,0,0,1,0,2H7A1,1,0,0,1,6,12Z" fill="currentColor" />
												</g>
												</g>
												<g id="Rectangle_644" data-name="Rectangle 644" transform="translate(568 -11561)" fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="2" stroke-dasharray="4 3">
												<rect width="24" height="24" rx="5" stroke="none"/>
												<rect x="1" y="1" width="22" height="22" rx="4" fill="none"/>
												</g>
											</g>
										</svg>
									</div>
									<div className="active">
										<svg clip-rule="evenodd" width="16" height="16" fill-rule="evenodd" image-rendering="optimizeQuality" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" viewBox="0 0 2.54 2.54" xmlns="http://www.w3.org/2000/svg">
											<g id="&#x56FE;&#x5C42;_x0020_1">
												<path d="m.37253 0h1.79494c.20518 0 .37253.16735.37253.37253v1.79494c0 .20518-.16735.37253-.37253.37253h-1.79494c-.20518 0-.37253-.16735-.37253-.37253v-1.79494c0-.20518.16735-.37253.37253-.37253z" fill="#48b02c" />
												<g id="_493074688" fill="#fff">
													<path id="_493075912" d="m.49106 1.14116h1.55788c.0373 0 .06773.03043.06773.06773v.12222c0 .0373-.03043.06773-.06773.06773h-1.55788c-.0373 0-.06773-.03043-.06773-.06773v-.12222c0-.0373.03043-.06773.06773-.06773z" />
													<path id="_493075216" d="m1.14116 2.04894v-1.55788c0-.0373.03043-.06773.06773-.06773h.12222c.0373 0 .06773.03043.06773.06773v1.55788c0 .0373-.03043.06773-.06773.06773h-.12222c-.0373 0-.06773-.03043-.06773-.06773z" />
												</g>
											</g>
										</svg>
									</div>

								</div>
							</PermissionAllowed>
						</li>
					</PermissionAllowed>

					<PermissionAllowed permissions={['List Serials (Page)']}>
						<li className={`nav-item main-menu ${pathname.includes('/serials') ? 'active' : ''}`}>
							<Link
								to={'/serials'}
								className={`nav-link`}
								onClick={() => {
									setShowSalesSubmenu(false);
								}}
							>
								<span className="menuIcon">
									<SerialsIcon width="16" height="16" />
								</span>
								<span className="menuLabel">
									<div className="sidebar-title-backside"></div><div>Serials</div>
								</span>
							</Link>
						</li>
					</PermissionAllowed>

					{/* <PermissionAllowed permissions={['List Stock (Page)']}>
						<li className={`nav-item ${pathname.includes('/inventory/') ? 'active' : ''}`}>
							<Link
								className="nav-link collapsed"
								to="#"
								data-toggle="collapse"
								data-target="#collapseTwo"
								aria-expanded="true"
								aria-controls="collapseTwo"
								onClick={() => setShow(show === 'products' ? '' : 'products')}
							>
								<span className="menuIcon">
									<ProductsIcon width="16" height="16" />
								</span>
								<span>Inventory</span>
							</Link>
							<Collapse in={show === 'products'}>
								<div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
									<div className=" collapse-inner rounded">
										<Link to={'/inventory/inbound'} className={`collapse-item ${pathname.includes('/inbound') ? 'active' : ''}`}>
											<span className="menuIcon">
												<InboundIcon width="16" height="16" />
											</span>
											<span>Inbound</span>
										</Link>
										<Link to={'/inventory/outbound'} className={`collapse-item ${pathname.includes('/outbound') ? 'active' : ''}`}>
											<span className="menuIcon">
												<OutboundIcon width="16" height="16" />
											</span>
											<span>Outbound</span>
										</Link>
									</div>
								</div>
							</Collapse>
						</li>
					</PermissionAllowed> */}
					<PermissionAllowed permissions={['List Report (Page)']}>
						<li className={`nav-item main-menu hide-on-mobile ${pathname.includes('/report') ? 'active' : ''}`}>
							<Link
								to={'/report'}
								className={`nav-link`}
								onClick={() => {
									mobile && closeMobileMenu && closeMobileMenu();
									setShowSalesSubmenu(false);
								}}
							>
								<span className="menuIcon">
									<TbReportAnalytics style={{ fontSize: '1.5em' }} />
								</span>
								<span className="menuLabel">
									<div className="sidebar-title-backside"></div><div>Reports</div>
								</span>
							</Link>
						</li>
					</PermissionAllowed>

					<li className="nav-break">&nbsp;</li>

					<PermissionAllowed permissions={['List User (Page)', 'List Role (Page)']} hasSome>
						<li className={`nav-item main-menu nav-item-settings hide-on-mobile ${pathname.includes('settings') ? 'active' : ''}`}>
							<Link
								to={'/settings'}
								className={`nav-link`}
								onClick={() => {
									mobile && closeMobileMenu && closeMobileMenu();
									setShowSalesSubmenu(false);
								}}
							>
								<span className="menuIcon">
									<SettingsIcon width="16" height="16" />
								</span>
								<span className="menuLabel"><div>Settings</div></span>
							</Link>
						</li>
					</PermissionAllowed>

					{/* <PermissionAllowed permissions={['List Model (Page)', 'List User (Page)', 'List Role (Page)']} hasSome>
						<li className={`nav-item nav-item-settings hide-on-mobile ${pathname.includes('settings') ? 'active' : ''}`}>
							<Link
								className="nav-link collapsed"
								to={'/settings'}
								data-toggle="collapse"
								data-target="#collapseTwo"
								aria-expanded="true"
								aria-controls="collapseTwo"
								onClick={() => {
									setShow(show === 'settings' ? '' : 'settings');
									setShowSalesSubmenu(false);
								}}
							>
								<span className="menuIcon">
									<SettingsIcon width="16" height="16" />
								</span>
								<span style={{ width: '130px' }}>Settings</span>
							</Link>
							<Collapse in={show === 'settings'}>
								<div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
									<div className="collapse-inner rounded">
										<PermissionAllowed permissions={['List Role (Page)']}>
											<Link
												to={'/settings/role'}
												className={`collapse-item ${pathname.includes('role') ? 'active' : ''}`}
												onClick={() => {
													setShowSalesSubmenu(false);
												}}
											>
												<span className="menuIcon">
													<RolesIcon width="16" height="16" />
												</span>
												<span>Roles</span>
											</Link>
										</PermissionAllowed>

										<Link
											to={'/settings/productcategories'}
											className={`collapse-item ${pathname.includes('/productcategories') ? 'active' : ''}`}
											onClick={() => {
												setShowSalesSubmenu(false);
											}}
										>
											<span className="menuIcon">
												<LuMonitorSmartphone style={{ fontSize: '1.3em' }} />
											</span>
											<span>Categories</span>
										</Link>
										<PermissionAllowed permissions={['List User (Page)']}>
											<Link
												to={'/settings/user'}
												className={`collapse-item ${pathname.includes('/user') ? 'active' : ''}`}
												onClick={() => {
													setShowSalesSubmenu(false);
												}}
											>
												<span className="menuIcon">
													<UsersIcon width="16" height="16" />
												</span>
												<span>Users</span>
											</Link>
										</PermissionAllowed>
									</div>
								</div>
							</Collapse>
						</li>
					</PermissionAllowed> */}
				</ul>
				<div className="currency-div">
					<CurrencyDropdown isOpen={mobile ? true : isOpened} />

					{!mobile && (
						<div
							className="collapse-tag"
							onClick={() => {
								setIsOpened(!isOpened);
							}}
						>
							{isOpened ? <FaChevronLeft /> : <FaChevronRight />}
						</div>
					)}
				</div>
			</div>
		</>
	);
};

// Sidebar.propTypes = {
//   drawerOpen: PropTypes.bool,
//   drawerToggle: PropTypes.func,
//   window: PropTypes.object
// }

export default Sidebar;
