import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
	storeSearchInvoice,
	storeSearchQuotation,
	setstateCollectionList,
	storeHeaderActions,
	storeInLocalStore
} from 'store/reducers/mainSlice';
import Pagination from 'ui-component/Pagination';
import Scroller from 'ui-component/Scroller';
import OrderItemsTable from 'views/estimation/statusComponents/OrderItemsTable';

import Acceptpopup from './Acceptpopup';
import { FaArrowRight, FaCheck, FaInfoCircle, FaPen, FaPlus, FaSave, FaSearch, FaTimesCircle, FaTrash } from 'react-icons/fa';
import Filter from 'ui-component/filter-menu/Filter';
import moment from 'moment';
import { LiaTimesSolid } from 'react-icons/lia';
import { Avatar, Tooltip } from '@mui/material';
import CurrencyValue from 'ui-component/CurrencyValue';
import AddToPurchaseListConfirmation from 'ui-component/confirmation-dialogs/addToPurchaseListConfirmation/AddToPurchaseListConfirmation';
import toast from 'react-hot-toast';
import PaymentFormModal from './paymentComponents/PaymentFormModal';
import PermissionAllowed from 'ui-component/permissions/PermissionAllowed';
import ActionConfirmationModal from 'ui-component/confirmation-dialogs/actionConfirmationModal/ActionConfirmationModal';
import { PiInfoFill, PiMicrosoftExcelLogoFill } from 'react-icons/pi';
import DeleteConfirmation from 'ui-component/confirmation-dialogs/deleteConfirmation/DeleteConfirmation';
import PrivacyModeContent from 'ui-component/PrivacyModeContent';
import { LuChevronDown } from 'react-icons/lu';
import { CommonDateOnlyFormat, getLocalStorageCacheItem, setLocalStorageCacheItem } from 'helpers/common.helper';
import { TbDatabaseExclamation, TbReportMoney } from 'react-icons/tb';
import { useQuery, useQueryClient } from 'react-query';
import md5 from 'md5-hash';
import { ImSpinner2 } from 'react-icons/im';
import {
	AcceptedIcon,
	AllIcon,
	ClosedIcon,
	DraftIcon,
	EstimateIcon,
	OpenOrderIcon,
	PendingOrderIcon,
	SourcingIcon,
	PaymentTransactionsIcon,
} from 'ui-component/custom-icons/icons';
import usePageTitle from 'hooks/usePageTitle';
import { FaArrowsRotate, FaChartBar, FaMoneyBill, FaUserTie } from 'react-icons/fa6';
import { MdKeyboardArrowDown, MdOutlineSettingsBackupRestore } from 'react-icons/md';
import SortableHeader from 'ui-component/SortableHeader';
import ExportListItemsModal from 'ui-component/ExportListItemsModal';
import { getCurrencySymbol, numberFormatter } from 'utils/currency';
import QuickStatsBar from 'ui-component/QuickStatsBar';
import AcceptOrderModal from 'ui-component/confirmation-dialogs/acceptOrderModal/AcceptOrderModal';
import { CompressOutlined } from '@mui/icons-material';

const index = () => {
	const [setPageTitle] = usePageTitle('Orders');
	// useEffect(() => {
	// 	return () => {
	// 		setPageTitle(null);
	// 	};
	// }, []);
	const history = useNavigate();
	const [selectedCurrency] = useSelector((state) => [state.main.selectedCurrency]);
	const [isDisabled, setIsDisabled] = useState(false);
	const [show, setShow] = useState('');
	const masterStatuses = useSelector((state) => state.main.masterStatuses);
	const statusTypes = useSelector((state) => state.main.statusTypes);
	const statusTypeList = statusTypes?.map((item) => ({ id: item.id, name: item.name }));
	const [isFiltersLoading, setIsFiltersloading] = useState(false);
	const [activeComponent, setActiveComponent] = useState('All');
	const [currentitem, setCurrentItem] = useState([]);
	const [openRP, setOpenRP] = useState(false);
	const dispatch = useDispatch();

	const [searchField, setSearchField] = useState();
	const [filters, setFilters] = useState([]);
	const [activeFilters, setActiveFilters] = useState({});
	const [sortByFilter, setSortByFilter] = useState();
	const navMobileActions = useSelector((state) => state.main.navMobileActions);
	const headerActions = useSelector((state) => state.main.headerActions);
	const [moduleName, setModuleName] = useState('');

	const location = useLocation();
	const pathParts = location.pathname.split('/');
	const currentPath = pathParts[pathParts.length - 1];

	const storedSearch = (currentPath === 'invoices') ? useSelector((state) => state.main.searchInvoice) : useSelector((state) => state.main.searchQuotation);

	const storedFilter = (currentPath === 'invoices') ? useSelector((state) => state.main.filterInvoice) : useSelector((state) => state.main.filterQuotation);


    useEffect(() => {
        // if (storedSearch) {
        //     handleSearch({ target: { value: storedSearch } });
        // }
        const fetchData = async () => {
            if (storedSearch) {
                setIsLoading(true);
                await handleSearch({ target: { value: storedSearch } });
            } else if (!storedSearch) {
                // if (storedFilter && Object.keys(storedFilter).length > 0) {
                //     // setIsLoading(true);
                //     // await handleFilterRedux();
                // } else {
                //     handleFilter()
                // }
            }
        };

        fetchData();
    }, [storedSearch, searchField, location, storedFilter]);

	useEffect(() => {
		initialRender()
		if (currentPath == 'quotations') {
			setPageTitle('Quotations')
			setActiveComponent('Quotation');
		} else if (currentPath == 'invoices') {
			setPageTitle('Invoices')
			setActiveComponent('Invoice');
		} else {
			setPageTitle('Sales')
			setActiveComponent('All');
		}

	}, [location, activeComponent]);

	useEffect(() => {
		return () => {
			setPageTitle(null);
		};
	}, []);
	const updateSortByStatus = (name) => {
		if (sortByFilter?.field == name) {
			setSortByFilter((e) => ({
				...e,
				direction: e?.direction == 'asc' ? 'desc' : 'asc'
			}));
		} else {
			setSortByFilter((e) => ({
				field: name,
				direction: 'asc'
			}));
		}
	};

	const [selectedEstimate, setSelectedEstimate] = useState(null);

	const [addToPl, setAddToPl] = useState(null);
	const [addToPlId, setAddToPlId] = useState(null);
	const [paginationUrl, setPaginationUrl] = useState();

	const queryClient = useQueryClient();

	const {
		data: estReqData,
		isLoading,
		isFetching
	} = useQuery(
		['estimations', activeComponent, activeFilters, searchField, paginationUrl, sortByFilter],
		() => {
			// const currentActiveComponent = activeComponent === 'Invoice' ? 3 : 2;
			const currentActiveComponent = activeComponent === 'Invoice' ? statusTypeList?.find((item) => item?.name == 'Invoice')?.id : statusTypeList?.find((item) => item?.name == 'Estimation')?.id;
			return paginationUrl
				? api.plainAPIRequest(paginationUrl)
				: api.listingEstimation(
					// activeComponent == statusTypeList?.find((item) => item?.name == currentActiveComponent)?.id,
					currentActiveComponent,
					activeFilters,
					searchField?.length > 2 ? searchField : null,
					sortByFilter
				);
		},
		{
			enabled: !!statusTypeList,
			initialData: (e) => {
				let item = null;
				const currentActiveComponent = activeComponent === 'Invoice' ? statusTypeList?.find((item) => item?.name == 'Invoice')?.id : statusTypeList?.find((item) => item?.name == 'Estimation')?.id;
				if (paginationUrl) {
					item = getLocalStorageCacheItem(md5(paginationUrl));
				} else {
					item = getLocalStorageCacheItem(
						md5(
							api.listingEstimationApiURL(
								// activeComponent == 'Archived' ? 'Archived' : activeComponent == 'Draft' ? 'Draft' : statusTypeList?.find((item) => item?.name == activeComponent)?.id,
								currentActiveComponent,
								// thisStatus,
								activeFilters,
								searchField?.length > 2 ? searchField : null,
								sortByFilter
							)
						)
					);
				}
				return item ? item : undefined;
			},
			onSuccess: (e) => {
				setLocalStorageCacheItem(md5(e?.config?.url), e, moment().add(3, 'days').format('YYYY-MM-DD'));
			}
		}
	);

	const { data: counterReqData } = useQuery(
		['estimation-counter'],
		() => {
			return api.getPageOverviewCounter();
		},
		{
			keepPreviousData: true,
			enabled: true,
			initialData: (e) => {
				const item = getLocalStorageCacheItem(md5('estimation-counter'));

				return item ? item : undefined;
			},
			onSuccess: (e) => {
				setLocalStorageCacheItem(md5('estimation-counter'), e, moment().add(3, 'days').format('YYYY-MM-DD'));
			}
		}
	);

	const [confirmationDialog, setConfirmationDialog] = useState({
		show: false,
		message: '',
		title: '',
		handleConfirm: () => { },
		handleClose: () => { }
	});

	const handleCloseConfirmationDialog = () => {
		setConfirmationDialog({
			show: false,
			message: '',
			title: '',
			handleConfirm: () => { },
			handleClose: () => { }
		});
	};

	const getItemList = estReqData?.data?.data?.data;
	const paginationData = estReqData?.data?.data;
	const counterData = counterReqData?.data?.data;

	const [showQuickStats, setShowQuickStats] = useState(false);
	const [quickStatsLoading, setQuickStatsLoading] = useState(false);
	const [quickStatsData, setQuickStatsData] = useState(null);

	useEffect(() => {
		if (showQuickStats && !quickStatsLoading && activeComponent != 'Draft') {
			setQuickStatsLoading(true);
			api
				.quickSummaryOrder(
					statusTypeList?.find((item) => item?.name == activeComponent)?.id,
					activeFilters,
					searchField?.length > 2 ? searchField : null,
					sortByFilter
				)
				.then((res) => {
					setQuickStatsData(res.data);
					setQuickStatsLoading(false);
				});
		}
	}, [showQuickStats]);

	const showConfirmationDialog = (
		title,
		message = '',
		onConfirm = () => { },
		onClose = () => {
			handleCloseConfirmationDialog();
		}
	) => {
		setConfirmationDialog({
			show: true,
			message: message,
			title: title,
			handleConfirm: onConfirm,
			handleClose: onClose
		});
	};

	const initialRender = () => {
		getFiltersList();
	};

	const getFiltersList = () => {
		setIsFiltersloading(true);
		if (currentPath == 'quotations') {
			api.getEstimationFilter().then((res) => {
				setFilters(res.data);
				setIsFiltersloading(false);
			});
		} else {
			api.getInvoiceFilter().then((res) => {
				setFilters(res.data);
				setIsFiltersloading(false);
			});
		}
	};


    const handleSearch = (e) => {
        let text = e.target.value;
        setSearchField(text);
		if (currentPath === 'invoices') {
			dispatch(storeSearchInvoice(text));
		} else {
			dispatch(storeSearchQuotation(text));
		}
		setSearchField(text)
    };

	useEffect(() => {
		// alert('asd')
		// const queryParams = new URLSearchParams(location.search);
		// const tabParam = queryParams.get('tab');
		if (currentPath == 'quotations') {
			setActiveComponent('Quotation');
			setModuleName('Quotations');
		} else if (currentPath == 'invoices') {
			setActiveComponent('Invoice');
			setModuleName('Invoices');
			// usePageTitle('Invoices')
		} else {
			setActiveComponent('All');
			setModuleName('All');
			// usePageTitle('Sales')
		}
		// console.log(currentPath)
		// Get a specific query parameter
	}, []);

	const handleData = (data) => {
		setPaginationUrl(data);
	};

	useEffect(() => {
		setPaginationUrl(null);
		setShowQuickStats(false);
		dispatch(
			storeHeaderActions({
				showQuickStats: false,
				showExport: headerActions?.showExport
			})
		);
	}, [activeComponent, activeFilters, searchField, sortByFilter]);

	const invalidateEstQuery = () => {
		queryClient.invalidateQueries({ queryKey: ['estimations', activeComponent, activeFilters, searchField, paginationUrl, sortByFilter] });
		queryClient.invalidateQueries({ queryKey: ['estimation-counter'] });
		selectedEstimate && setSelectedEstimate(null);
	};

	useEffect(() => {
		queryClient.invalidateQueries({ queryKey: ['estimation-counter'] });
	}, [activeComponent]);

	const handleClose = () => setShow(null);

	const restoreEstimation = (data) => {
		showConfirmationDialog('Restore Estimation', 'Are you sure you want to restore this estimation?', () => {
			toast.promise(
				api.restoreEstimation(data.id).then((res) => {
					if (res.status) {
						handleCloseConfirmationDialog();
						invalidateEstQuery();
						setSelectedEstimate(null);
					}
				}),
				{
					loading: 'Restoring Estimation...',
					success: <b>Estimation Restored!</b>,
					error: <b>Could not restore estimation.</b>
				}
			);
		});
	};

	const deleteDraft = (data) => {
		setShow(data);
	};
	const deleteEstimate = (data) => {
		setShow(data);
	};
	const handleDeleteDraft = (id) => {
		toast.promise(
			api.deleteDraft(id).then((res) => {
				if (res.status) {
					setShow('');
					invalidateEstQuery();
					setSelectedEstimate(null);
				}

				return res?.status ? res : abc;
			}),
			{
				loading: 'Deleting Draft...',
				success: (data) => `${data.message}`,
				error: <b>Failed to delete draft</b>
			}
		);
	};

	const handleCancelEstimate = (id) => {
		toast.promise(
			api.cancelEstimation(id).then((res) => {
				if (res.status) {
					setShow('');
					invalidateEstQuery();
					setSelectedEstimate(null);
				}

				return res?.status ? res : abc;
			}),
			{
				loading: 'Canceling Quotation...',
				success: (data) => `${data.message}`,
				error: <b>Failed to cancel quotation</b>
			}
		);
	};

	const [showAcceptPopup, setShowAcceptPopup] = useState(null);

	const acceptPopup = (item) => {
		setShowAcceptPopup(item);
	};

	const resendAgreement = (data) => {
		const id = data.id;
		showConfirmationDialog('Resend Agreement', 'Resend Order agreement to customer?', () => {
			toast.promise(
				api.resendAgreementEstimate(id).then((res) => {
					if (res.status) {
						handleCloseConfirmationDialog();
						invalidateEstQuery();
					}
				}),
				{
					loading: 'Resending Agreement...',
					success: <b>Email Sent!</b>,
					error: <b>Could not send Email.</b>
				}
			);
		});
	};

	const approveOrder = (data) => {
		const id = data.id;
		toast.promise(
			api.approveEstimate({
				qoutation_id: id,
			}).then((res) => {
				if (res.status) {
					// handleCloseConfirmationDialog();
					invalidateEstQuery();
				}
			}),
			{
				loading: 'Approving Order...',
				success: <b>Order approved</b>,
				error: <b>Could not send Email.</b>
			}
		);
	};

	const handleAcceptConfirm = (id) => {
		toast.promise(
			api
				.acceptedEstimate({
					estimation_id: id
				})
				.then((res) => {
					if (res.status) {
						handleCloseConfirmationDialog();
						invalidateEstQuery();
						setShowAcceptPopup(null);
					}
				}),
			{
				loading: 'Accepting Quotation...',
				success: <b>Quotation Accepted!</b>,
				error: <b>Could not Accept Quotation.</b>
			}
		);
	};

	const resendAcceptOrderRequest = (data) => {
		toast.promise(
			api
				.resendAcceptedOrderRequest({
					estimation_id: data?.id
				})
				.then((res) => {
					// if (res.status) {
					// 	handleCloseConfirmationDialog();
					// 	invalidateEstQuery();
					// 	setShowAcceptPopup(null);
					// }
				}),
			{
				loading: 'Resending Request...',
				success: <b>Request sent to manager's approval</b>,
				error: <b>Could not resend request.</b>
			}
		);
	}

	const changeStatus = (data) => {
		const id = data.id;
		const status = data.status;
		showConfirmationDialog('Update Status', 'Are You sure you want to update the status of this order?', () => {
			toast.promise(
				api.changeestimateStatus({ estimation_id: id, status: status }).then((res) => {
					if (res.status) {
						handleCloseConfirmationDialog();
						invalidateEstQuery();
					}
				}),
				{
					loading: 'Updating Order Status...',
					success: <b>Order Updated!</b>,
					error: <b>Could not Update order status.</b>
				}
			);
		});
	};
	const addToPurchaseList = (id) => {
		setAddToPlId(id);
		console.log(id)
		setAddToPl(
			getItemList
				.find((item) => item?.id == id)
				?.estimation_details?.map((prod) => {
					return {
						id: prod?.product?.id,
						name: prod?.product?.product_name,
						qty: prod?.qty
					};
				}) || null
		);
	};

	const handleAddToPurchaseList = (showId, items) => {
		// toast.promise(
		// 	api.createpurchase(addToPlId).then((res) => {
		// 		if (res.status) {
		// 			invalidateEstQuery();
		// 			setAddToPl(null);
		// 			setAddToPlId(null);
		// 		}

		// 		return res?.status == true ? res : null;
		// 	}),
		// 	{
		// 		loading: 'Adding to purchase list...',
		// 		success: (data) => `${data.message}`,
		// 		error: <b>Could not add to purchase list</b>
		// 	}
		// );
		console.log('showId', showId, items);
		toast.promise(
			api.createbackorder(addToPlId, items).then((res) => {
				console.log('res', res);
				if (res.status) {
					invalidateEstQuery();
					setAddToPl(null);
					setAddToPlId(null);
				}

				return res?.status == true ? res : null;
			}),
			{
				loading: 'Adding to purchase list...',
				success: (data) => `${data.message}`,
				error: <b>Could not add to purchase list</b>
			}
		);
	};

	const handlePlDialogClose = () => {
		setAddToPl(null);
		setAddToPlId(null);
	};

	const addPayment = (item) => {
		setOpenRP(true);
		setCurrentItem(item);
	};

	// const handlePaymentFormSubmit = (e) => {
	// 	// console.log('e', e);
	// 	toast.promise(
	// 		api.addInvoicePayment({ ...e, estimation_id: currentitem?.id }).then((res) => {
	// 			if (res.status) {
	// 				closeRpPopup('reload');
	// 			}
	// 			return res?.status == true ? res : null;
	// 		}),

	// 		{
	// 			loading: 'Adding Payment...',
	// 			success: (data) => `${data.message}`,
	// 			error: <b>Payment Failed</b>
	// 		}
	// 	);
	// };

	const closeRpPopup = (data) => {
		if (data == 'reload') {
			invalidateEstQuery();
			setOpenRP(false);
		} else {
			setOpenRP(false);
		}
	};

	const viewDetails = (id) => {
		history(`${location.pathname}/${id}`);
	};

	const updateEstimate = (id) => {
		history(`${location.pathname}/edit/${id}`);
	};

	const updateDraft = (id) => {
		history(`${location.pathname}/draft/${id}`);
	};

	const [exportCompIsLoading, setExportCompIsLoading] = useState(false);
	const [showExportModal, setShowExportModal] = useState(false);

	const exportListSubmitHandler = (selectedValues) => {
		setExportCompIsLoading(true);
		// console.log('ditop yanb', currentPath)
		api.downloadListingEstimation('', selectedValues, '', selectedCurrency, currentPath).then((res) => {
			const newFileName = `Projectmix - ${(currentPath == 'invoices') ? 'Invoices' : 'Quotations'}`;
			if (res != 404) {
				var newBlob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
				saveAs(newBlob, `${newFileName}.xlsx`);
				// toast.dismiss(downloading);
				setExportCompIsLoading(false);
				toast.success('Downloaded Completed!');
				setShowExportModal(false);
				dispatch(
					storeHeaderActions({
						showQuickStats: headerActions?.showQuickStats,
						showExport: false
					})
				);
			} else {
				// toast.dismiss(downloading);
				setExportCompIsLoading(false);
				toast.error(res == 404 ? 'No Records Found!' : 'Download Failed!');
			}
		});
	};

	useEffect(() => {
		if (headerActions?.showQuickStats) {
			setShowQuickStats(true);
		} else {
			setShowQuickStats(false);
		}

		if (headerActions?.showExport) {
			setShowExportModal(true);
		} else {
			setShowExportModal(false);
		}
	}, [headerActions]);

	return (
		<>
			<div className="row">
				<div className="col-xl-12 col-lg-12">
					<div className="section-head-new pl-0 mar1020">
						<ul className="topListCom">
							<PermissionAllowed permissions={['Create Estimation (Button)']}>


								<button
									className="btn btn-primary btn-outlined-main-primary btn-create-report"
									onClick={() => {
										history(`${location.pathname}/create`);
									}}
								>
									<FaPlus /> New
								</button>

							</PermissionAllowed>
							{/* <li
								className={`${activeComponent === 'Estimation' ? 'active' : ''} ${isLoading && 'not-allowed-pointer'}`}
								onClick={() => {
									!isLoading && handleComponentChange('Estimation');
								}}
							>
								<span className="topListIcon">
									<EstimateIcon width={15} height={15} />
								</span>
								<span className="topListText">Estimates</span>
								{!isNaN(counterData?.Estimation) && <span className="topListCounter">({counterData?.Estimation})</span>}
							</li>
							<li
								className={`${activeComponent === 'Invoice' ? 'active' : ''} ${isLoading && 'not-allowed-pointer'}`}
								onClick={() => {
									!isLoading && handleComponentChange('Invoice');
								}}
							>
								<span className="topListIcon">
									<AcceptedIcon width={15} height={15} />
								</span>
								<span className="topListText">Invoices</span>
								{!isNaN(counterData?.Invoice) && <span className="topListCounter">({counterData?.Invoice})</span>}
							</li>
							<li
								className={`${activeComponent === 'All' ? 'active' : ''} ${isLoading && 'not-allowed-pointer'}`}
								onClick={() => {
									!isLoading && handleComponentChange('All');
								}}
							>
								<span className="topListIcon">
									<AllIcon width={15} height={15} />
								</span>
								<span className="topListText">All</span>
								{!isNaN(counterData?.All) && <span className="topListCounter">({counterData?.All})</span>}
							</li> */}
							{/* <Link
								className="d-md-none"
								to={'#'}
								onClick={() => {
									handleComponentChange('Draft');
								}}
							>
								{!isNaN(counterData?.Draft) && counterData?.Draft > 0 && <span>Drafts&nbsp;({counterData?.Draft})</span>}
							</Link> */}
						</ul>
						<div className="actions d-none d-md-flex">
							{/* <Link
								className=""
								to={'#'}
								onClick={() => {
									handleComponentChange('Archived');
								}}
							>
								{!isNaN(counterData?.Archived) && counterData?.Archived > 0 && <span>Canceled ({counterData?.Archived})</span>}
							</Link>
							<Link
								className=""
								to={'#'}
								onClick={() => {
									handleComponentChange('Draft');
								}}
							>
								{!isNaN(counterData?.Draft) && counterData?.Draft > 0 && <span>Drafts ({counterData?.Draft})</span>}
							</Link> */}
							{
								currentPath == 'invoices' && (
									<>
										<PermissionAllowed permissions={['List Payment Transaction']}>
											<button
												className="btn btn-primary btn-outlined-main white-var btn-create-report"
												disabled={isDisabled}
												onClick={() => {
													if (!isDisabled) {
														history(`/sales/transactions`);
													}
												}}
											>
												<PaymentTransactionsIcon height={'20px'} width={'20px'} /> Transactions
											</button>
										</PermissionAllowed>
									</>
								)
							}
							<button
								className="btn btn-primary btn-outlined-main white-var"
								style={{ minWidth: 'unset', paddingBlock: '4.1px' }}
								onClick={() => {
									setShowQuickStats(!showQuickStats);
								}}
							>
								<span style={{ fontSize: '1.1em' }}>
									<FaChartBar />
								</span>{' '}
								<span
									className="d-flex"
									style={{ transition: '0.3s ease-in-out', ...(showQuickStats ? { transform: 'rotate(180deg)' } : {}) }}
								>
									<MdKeyboardArrowDown />
								</span>
							</button>
							<PermissionAllowed permissions={['Export Estimation (Button)']}>
								{isDisabled ? (
									<button className="btn btn-primary btn-outlined-main white-var btn-create-report" disabled>
										{' '}
										<PiMicrosoftExcelLogoFill style={{ fontSize: '1.4em' }} /> Export
									</button>
								) : (
									<button
										className="btn btn-primary btn-outlined-main white-var btn-create-report"
										onClick={() => {
											setShowExportModal(true);
										}}
									>
										<PiMicrosoftExcelLogoFill style={{ fontSize: '1.4em' }} /> Export
									</button>
								)}
							</PermissionAllowed>
						</div>
					</div>
					<div className="card shadow-sm  bg-white mb-4 table-card">
						<div className="d-flex align-items-center justify-content-between TableSerachBar">
							<div>
								<Filter activeFilters={activeFilters} onSubmit={setActiveFilters} filters={filters} multi={undefined} source={`${currentPath === 'quotations' ? 'quotation' : 'invoice'}`} />
							</div>
							<form className="form-inline navbar-search" style={{ display: 'flex', gap: '5px' }} onSubmit={(e) => e.preventDefault()}>
								{isFetching && (
									<p className="m-0" style={{ display: 'flex', gap: '3px', alignItems: 'center', fontSize: '10px' }}>
										<ImSpinner2 className="fa-spin" />
									</p>
								)}

								<div className="input-group">
									<input
										type="text"
										className="form-control bg-white border"
										placeholder="Search"
										aria-label="Search"
										aria-describedby="basic-addon2"
										value={searchField}
										onChange={handleSearch}
										// onChange={(e) => {
										// 	setSearchField(e.target.value);
										// }}
										style={{ width: '100%' }}
									/>
									<span className="searchIcon">
										<FaSearch />
									</span>
								</div>
							</form>
						</div>
						<div className="estimation-table-area">
							<div className="table-side">
								<div className="table-responsive-xl tableData estimationList">
									<Scroller extraHeight={60}>
										{showQuickStats && (
											<div className="order-quick-stats">
												<div className="row w-100 m-0 p-0">
													<div className="col-12 col-md-6 m-0 p-0 mb-2 mb-md-none">
														<h5>
															{currentPath == 'quotations' ? (
																'Total Quotation'
															) : (
																<>
																	<span className="hide-on-mobile" style={{ fontSize: '1.25rem' }}>Recorded as revenue</span>
																	<span className="d-md-none">Revenue</span>
																</>
															)}{' '}
															<b>
																<CurrencyValue
																	currencyObject={
																		activeComponent == 'Estimation' ? quickStatsData?.estimate_value : quickStatsData?.total_value
																	}
																/>
															</b>
														</h5>
														<p className="hide-on-mobile">in current selection</p>
													</div>
													{currentPath != 'quotations' && (
														<div className="col-12 col-md-6 m-0 p-0">
															<h5>
																To receive{' '}
																<b>
																	<CurrencyValue currencyObject={quickStatsData?.open_value} />
																</b>
															</h5>
															<p className="hide-on-mobile">in current selection</p>
														</div>
													)}
												</div>
												{
													currentPath == 'quotations' ? (
														<>
															<QuickStatsBar
																data={[
																	{ name: 'Drafts', color: '#acacac', value: quickStatsData?.estimate_draft, count: quickStatsData?.total },
																	{ name: 'Pending', color: '#ffc007', value: quickStatsData?.estimate_pending, count: quickStatsData?.open },
																	{ name: 'Accepted', color: '#3ac780', value: quickStatsData?.estimate_accepted, count: quickStatsData?.open },
																	{ name: 'Canceled', color: '#343a3e', value: quickStatsData?.estimate_canceled, count: quickStatsData?.open },
																	// ...(currentPath == 'estimates'
																	// 	? []
																	// 	: [
																	// 		{ name: 'Pending', color: '#ffc007', value: quickStatsData?.estimate_pending, count: quickStatsData?.open },
																	// 		{ name: 'Invoiced', color: '#70DD36', value: quickStatsData?.estimate_invoiced, count: quickStatsData?.paid }
																	// 	])
																]}
															/>
														</>
													) : (
														<>
															<QuickStatsBar
																data={[
																	{ name: 'Drafts', color: '#acacac', value: quickStatsData?.estimate_value, count: quickStatsData?.total },
																	...(activeComponent == 'Estimation'
																		? []
																		: [
																			{ name: 'Open', color: '#c28100', value: quickStatsData?.open_value, count: quickStatsData?.open },
																			{ name: 'Paid', color: '#28a745', value: quickStatsData?.paid_value, count: quickStatsData?.paid },
																			{ name: 'Canceled', color: '#343a40', value: quickStatsData?.invoice_canceled, count: quickStatsData?.paid },
																		])
																]}
															/>
														</>
													)
												}
											</div>
										)}
										<table className="table  min-width-unset tbl-space pm-table d-none d-md-table">
											{activeComponent == 'CreditNote' ? (
												<thead>
													<tr>
														<th scope="col" width="20%">
															Credit Note
														</th>
														<th scope="col">Customer</th>
														<th scope="col">Date</th>
														<th scope="col">Total</th>
														<th scope="col" className="text-center" width={'180'}>
															Action
														</th>
													</tr>
												</thead>
											) : (
												<thead>
													<tr className="sticky-header">
														{activeComponent == 'Draft' ? (
															<>
																<th scope="col" width="">
																	Reference
																</th>
															</>
														) : activeComponent == 'Quotation' ? (
															<>
																<th scope="col" width="">
																	<SortableHeader
																		name="estimation_number"
																		currentSortBy={sortByFilter}
																		onClick={() => {
																			updateSortByStatus('estimation_number');
																		}}
																	>
																		Reference
																	</SortableHeader>
																</th>

																<th scope="col" width="">
																	<SortableHeader
																		name="customer"
																		currentSortBy={sortByFilter}
																		onClick={() => {
																			updateSortByStatus('customer');
																		}}
																	>
																		Customer
																	</SortableHeader>
																</th>
															</>
														) : (
															<>
																<th scope="col" width="">
																	<SortableHeader
																		name="invoice_number"
																		currentSortBy={sortByFilter}
																		onClick={() => {
																			updateSortByStatus('invoice_number');
																		}}
																	>
																		Reference
																	</SortableHeader>
																</th>
																<th scope="col" width={''}>
																	<SortableHeader
																		name="transit_company"
																		currentSortBy={sortByFilter}
																		onClick={() => {
																			updateSortByStatus('transit_company');
																		}}
																	>
																		Transit
																	</SortableHeader>
																</th>
															</>
														)}
														<th scope="col" width="">
															<SortableHeader
																name="manager"
																currentSortBy={sortByFilter}
																onClick={() => {
																	updateSortByStatus('manager');
																}}
															>
																Manager
															</SortableHeader>
														</th>
														<th scope="col">
															<SortableHeader
																name="date"
																currentSortBy={sortByFilter}
																onClick={() => {
																	updateSortByStatus('date');
																}}
															>
																Date
															</SortableHeader>
														</th>


														{currentPath == 'quotations' ? (
															<>
																<th scope="col" width={''}>
																	<SortableHeader
																		name="transit_company"
																		currentSortBy={sortByFilter}
																		onClick={() => {
																			updateSortByStatus('transit_company');
																		}}
																	>
																		Transit
																	</SortableHeader>
																</th>
															</>
														) : (
															''
														)}
														<th scope="col">
															Qty
														</th>
														<th scope="col">
															<SortableHeader
																name="total"
																currentSortBy={sortByFilter}
																onClick={() => {
																	updateSortByStatus('total');
																}}
															>
																Total
															</SortableHeader>
														</th>
														{selectedEstimate ? (
															''
														) : (
															<>
																<th scope="col">
																	<SortableHeader
																		name="status"
																		currentSortBy={sortByFilter}
																		onClick={() => {
																			updateSortByStatus('status');
																		}}
																		style={{ maxWidth: '100px' }}
																	>
																		Status
																	</SortableHeader>
																</th>
															</>
														)}
													</tr>
												</thead>
											)}
											<OrderItemsTable
												row={getItemList || []}
												isLoading={isLoading}
												currentStatus={activeComponent}
												deleteDraft={deleteDraft}
												deleteEstimate={deleteEstimate}
												acceptpopup={acceptPopup}
												changeStatus={changeStatus}
												addtopurchagelist={addToPurchaseList}
												toggleShowDetails={setSelectedEstimate}
												addrp={addPayment}
												selectedEstimate={selectedEstimate}
												setSelectedEstimate={setSelectedEstimate}
												viewDetails={viewDetails}
												updateEstimate={updateEstimate}
											/>
										</table>

										<div className="inv-cards-list d-md-none">
											{getItemList?.map((item, index) => (
												<div className="order-list-card active-action" onClick={() => viewDetails(item?.id)}>
													<div className="order-list-card-header">
														<h6 className="order-no">
															{item?.invoice_number
																? item?.invoice_number
																: item?.estimation_number
																	? item?.estimation_number
																	: item?.draft_number}
														</h6>
														<p className="order-date">{moment(item?.start_date).format(CommonDateOnlyFormat)}</p>
													</div>

													<div className="order-list-card-body">
														<div className="order-contacts">
															<p className="order-customer">
																<PrivacyModeContent>{item?.customer?.name}</PrivacyModeContent>
															</p>
															{item?.transit_company && (
																<p className="order-transit">
																	via{' '}
																	<PrivacyModeContent notUserRestricted>
																		{item?.transit_company?.name
																			? item?.transit_company?.name && item.transit_company?.name.length > 20
																				? item.transit_company?.name.substring(0, 20) + '...'
																				: item?.transit_company?.name
																			: '-'}
																	</PrivacyModeContent>
																</p>
															)}
														</div>
														<p className="total-val">
															<CurrencyValue currencyObject={item?.converted_currency_total} />
														</p>
													</div>

													<div className="order-list-card-footer">
														<div className="order-icon-items">
															{item?.master_status?.name != 'Credit Note' && !item?.is_credit_note && (
																<div className="order-icon-item order-manager">
																	<span className="icons-span">
																		<Avatar sx={{ width: 20, height: 20 }} style={{ fontSize: 10 }} src={item?.sales_person?.user_profile}>
																			{item?.sales_person?.first_name?.split(' ')?.[0]?.slice(0, 1)}
																			{item?.sales_person?.last_name?.split(' ')?.[1]?.slice(0, 1)}
																		</Avatar>
																	</span>
																	<span>
																		{item?.sales_person?.first_name
																			? `${item?.sales_person?.first_name} ${item?.sales_person?.last_name}`
																			: '-'}
																	</span>
																</div>
															)}
															{item?.master_status?.name != 'Draft' &&
																item?.master_status?.name != 'Estimated' &&
																item?.master_status?.name != 'Pending Manager' &&
																// item?.master_status?.name != 'Pending Customer' &&
																item?.master_status?.name != 'Pending' &&
																item?.master_status?.name != 'Credit Note' &&
																item?.master_status?.name != 'Cancelled' && (
																	<div
																		className={`order-icon-item  ${item?.paid_status == 'Paid'
																			? 'order-paid'
																			: item?.paid_status == 'Partial'
																				? 'order-partial-paid'
																				: 'order-unpaid'
																			}`}
																	>
																		<span className="icons-span">
																			<FaMoneyBill />
																		</span>
																		<span>{item?.paid_status}</span>
																	</div>
																)}
														</div>

														<div className={`order-status order-status-${item?.master_status?.name?.toLowerCase()?.replaceAll(' ', '-')}`}>
															{item?.master_status?.name}
														</div>
													</div>
												</div>
											))}
											{getItemList?.length < 1 && (
												<div className="w-100">
													<div className="not-found-container">
														<div className="not-found-icon">
															<TbDatabaseExclamation />
														</div>
														<h6 className="not-found-text">No Data Available</h6>

														{
															currentPath == '/sales/quotations' ? (
																<>
																	<Link to={'/sales/quotations/create'} className="btn btn-primary btn-outlined-main">
																		Create new quotation ?
																	</Link>
																</>
															) : (
																<>
																	<Link to={'/sales/invoices/create'} className="btn btn-primary btn-outlined-main">
																		Create new invoice ?
																	</Link>
																</>
															)
														}
													</div>
												</div>
											)}
										</div>
									</Scroller>
								</div>
								<Pagination pagination={paginationData} data={handleData} onLoading={(e) => { }} reactQuery />
							</div>

							{/* {selectedEstimate &&
								[selectedEstimate].map((item) => {
									const exp_date = `Due ${moment().to(item?.expires_at)}`;
									const start_dates = `${moment(item?.start_date).format(CommonDateOnlyFormat)}`;
									const maxCharacters = 20;
									let currencyRate = 0;
									let activeStatus = activeComponent == 'All' ? item.master_status?.name : activeComponent;
									const salesPersonFullName = `${item?.sales_person?.first_name} ${item?.sales_person?.last_name || ''}`;
									const truncatedSalesPersonFullName =
										salesPersonFullName && salesPersonFullName.length > maxCharacters
											? salesPersonFullName.substring(0, maxCharacters) + '...'
											: salesPersonFullName;
									let currencySymbol = '';
									currencyRate = 1;
									currencySymbol = item.currency == 'eur' ? '€' : item.currency == 'usd' ? '$' : 'AED';

									return (
										<div className="estimate-detail-container">
											<div className="row m-0 p-2">
												<div className="col-12">
													<div className="d-flex justify-content-between align-items-center">
														<h6 className="est-title">{item?.estimation_number}</h6>
														<LiaTimesSolid style={{ cursor: 'pointer' }} onClick={() => setSelectedEstimate(null)} />
													</div>
													<table className="table-sm  table-borderless" width="100%">
														<tbody>
															<tr>
																<td className="align-middle">
																	{selectedEstimate?.master_status?.name != 'Credit Note' ? 'Starts From' : 'Date'}{' '}
																</td>
																<td className="align-middle">{start_dates}</td>
															</tr>

															{selectedEstimate?.master_status?.name != 'Credit Note' && (
																<tr>
																	<td className="align-middle">Expires on:</td>
																	<td className="align-middle">{exp_date}</td>
																</tr>
															)}

															{
																selectedEstimate?.logistic?.name && (
																	<>
																		<tr>
																			<td className="align-top" width="190px">
																				Logstic
																			</td>
																			<td className="align-top">
																				<PrivacyModeContent>
																					{
																						item?.logistic?.name && item.logistic?.name.length > 20
																							? item.logistic?.name.substring(0, 20) + '...'
																							: item?.logistic?.name
																					}
																				</PrivacyModeContent>
																			</td>
																		</tr>
																	</>
																)
															}
															{selectedEstimate?.master_status?.name != 'Credit Note' && (
																<tr>
																	<td className="align-top" width="190px">
																		Sales Manager
																	</td>
																	<td className="align-top">
																		{`${item?.sales_person?.first_name || ''} ${item?.sales_person?.last_name || ''
																			}`}
																	</td>
																</tr>
															)}
															<tr>
																<td className="align-top" width="190px">
																	Transit Company
																</td>
																<td className="align-top">
																	<PrivacyModeContent>
																		{item?.transit_company?.name && item.transit_company?.name.length > 20
																			? item.transit_company?.name.substring(0, 20) + '...'
																			: item?.transit_company?.name}
																	</PrivacyModeContent>
																</td>
															</tr>
														</tbody>
													</table>
												</div>
												<div className="col-12">
													<div className="table-responsive-xl CollapseTable">
														<div className="productsTable">
															<table className="table table-md formActBtns border-0">
																<thead>
																	<tr>
																		<th className="align-middle">Model</th>
																		<th className="align-middle text-right p-1">Quantity</th>
																		<th className="align-middle text-right p-1">Total</th>
																	</tr>
																</thead>
																<tbody>
																	{item?.estimation_details &&
																		item?.estimation_details.map((model, index) => (
																			<tr key={index}>
																				<td className="align-middle form-row estimateCName">
																					<div className="form-group col-md-12 mb-0">{model?.product?.product_name}</div>
																				</td>
																				<td className="align-middle text-right p-1">{model?.qty} Pcs</td>
																				<td className="align-middle text-right p-1">
																					<CurrencyValue currencyObject={model?.converted_currency_total_price} />
																				</td>
																			</tr>
																		))}
																</tbody>

																<tfoot>
																	{item?.shipping_fees * item?.shipping_qty > 0 && (
																		<tr className="vatTotal graybg">
																			<td className="align-middle p-1"></td>
																			<td className="align-middle p-1 text-right">Shipping</td>
																			<td className="align-middle p-1 text-right font-weight-bold">
																				<CurrencyValue currencyObject={item?.converted_currency_total_shipping_fee} />
																			</td>
																		</tr>
																	)}
																	{item?.vat_type == 'Excluding VAT' && (
																		<tr className="vatTotal graybg">
																			<td className="align-middle p-1"></td>
																			<td className="align-middle p-1 text-right">VAT ({item?.vat_percent}%)</td>
																			<td className="align-middle p-1 text-right font-weight-bold">
																				<CurrencyValue currencyObject={item?.converted_currency_vat_amount} />
																			</td>
																		</tr>
																	)}
																	<tr className="vatTotal graybg">
																		<td className="align-middle p-1"></td>
																		<td className="align-middle p-1 text-right">Total</td>
																		<td className="align-middle p-1 text-right font-weight-bold">
																			<CurrencyValue currencyObject={item?.converted_currency_total} />
																		</td>
																	</tr>
																</tfoot>
															</table>
														</div>
													</div>
												</div>

												<div className="col-12 estimate-details-actions mt-4">
													{item.master_status?.name === 'Pending Manager' && (
														<>
															<PermissionAllowed permissions={['Accept Estimation (Button)']}>
																<Tooltip title="Accept" arrow placement="top">
																	<span className="btnEdit plain-textbtn" onClick={() => acceptPopup(item)}>
																		<FaCheck /> Accept
																	</span>
																</Tooltip>
															</PermissionAllowed>
															<Tooltip title="Resend Accept Order Request" arrow placement="top">
																<span className="btnEdit plain-textbtn" onClick={() => resendAcceptOrderRequest(item)}>
																	<FaCheck /> Resend Accept Order
																</span>
															</Tooltip>
														</>
													)}

													<>
														{(item.master_status?.name == 'Invoiced') && (
															<PermissionAllowed permissions={['Add to Purchase List (Action)']}>
																<span
																	className="btnEdit plain-textbtn"
																	onClick={() => {
																		addToPurchaseList(item.id);
																	}}
																>
																	<FaArrowsRotate /> Process Order
																</span>
															</PermissionAllowed>
														)}
													</>

													{item.master_status?.name === 'Pending' && (
														<>
															<PermissionAllowed permissions={['Approve Estimation (Button)']}>
																<Tooltip title="Approve" arrow placement="top">
																	<span className="btnEdit plain-textbtn" onClick={() => approveOrder(item)}>
																		<FaCheck /> Approve
																	</span>
																</Tooltip>
															</PermissionAllowed>
															<Tooltip title="Resend Agreement" arrow placement="top">
																<span className="btnEdit plain-textbtn" onClick={() => resendAgreement(item)}>
																	<FaCheck /> Resend Agreement
																</span>
															</Tooltip>
														</>
													)}

													{item.master_status?.name == 'Open' &&
														(item.open_balance.open <= 0 ? (
															<>
																<span
																	onClick={() =>
																		changeStatus({
																			id: item?.id,
																			status: 'Pending'
																		})
																	}
																	className="btnEdit plain-textbtn"
																>
																	<FaArrowRight /> Move to pending
																</span>
															</>
														) : (
															<>
																<PermissionAllowed permissions={['RP Estimation (Button)']}>
																	<span onClick={() => addPayment(item)} className="btnEdit plain-textbtn">
																		<FaPlus /> Register Payment
																	</span>
																</PermissionAllowed>
															</>
														))}

													<>
														{item.master_status?.name != 'Draft' && (
															<PermissionAllowed permissions={['View Estimation (Button)']}>
																<span
																	className="btnEdit plain-textbtn"
																	onClick={() => {
																		viewDetails(item?.id);
																	}}
																>
																	<FaInfoCircle /> Details
																</span>
															</PermissionAllowed>
														)}
													</>

													<>
														<PermissionAllowed permissions={['Edit Estimation (Button)']}>
															{(item.master_status?.name == 'Estimated' || item.master_status?.name == 'Pending Manager' || item.master_status?.name == 'Pending') && (
																<span
																	className="btnEdit plain-textbtn"
																	onClick={() => {
																		updateEstimate(item?.id);
																	}}
																>
																	<FaPen /> Edit
																</span>
															)}
														</PermissionAllowed>
														{item.master_status?.name == 'Draft' && (
															<span
																className="btnEdit plain-textbtn"
																onClick={() => {
																	updateDraft(item?.id);
																}}
															>
																<FaPen /> Edit
															</span>
														)}
														<PermissionAllowed permissions={['Cancel Estimation (Button)']}>
															{(item.master_status?.name == 'Estimated' || item.master_status?.name == 'Pending Manager' || item.master_status?.name == 'Pending') && (
																<span
																	className="btnEdit plain-textbtn"
																	onClick={() => {
																		deleteDraft(item);
																	}}
																>
																	<FaTimesCircle /> Cancel
																</span>
															)}
														</PermissionAllowed>
														<PermissionAllowed permissions={['Restore Cancelled Estimation (Button)']}>
															{item.master_status?.name == 'Canceled' && (
																<span
																	className="btnEdit plain-textbtn"
																	onClick={() => {
																		restoreEstimation(item);
																	}}
																>
																	<MdOutlineSettingsBackupRestore /> Restore
																</span>
															)}
														</PermissionAllowed>
													</>

													<>
														<PermissionAllowed permissions={['Delete Estimation (Button)']}>
															{activeComponent == 'Draft' && (
																<span
																	className="btnEdit plain-textbtn"
																	onClick={() => {
																		deleteDraft(item);
																	}}
																>
																	<FaTrash /> Delete
																</span>
															)}
														</PermissionAllowed>
													</>
												</div>
											</div>
										</div>
									);
								})} */}
						</div>
					</div>
				</div>
			</div>

			<AddToPurchaseListConfirmation show={addToPl} handleClose={handlePlDialogClose}
				handleConfirm={(showId, items) => handleAddToPurchaseList(showId, items)} />
			{showAcceptPopup && (
				<AcceptOrderModal show={showAcceptPopup} handleClose={() => setShowAcceptPopup(null)} handleConfirm={handleAcceptConfirm} />
			)}
			{confirmationDialog?.show && (
				<ActionConfirmationModal
					show={confirmationDialog?.show}
					message={confirmationDialog?.message}
					title={confirmationDialog?.title}
					customConfirmText={confirmationDialog?.customConfirm || 'Confirm'}
					customCancelText={confirmationDialog?.customCancel || 'Cancel'}
					customIcon={<PiInfoFill />}
					handleConfirm={confirmationDialog?.handleConfirm}
					handleClose={confirmationDialog?.handleClose}
				/>
			)}
			{currentitem && openRP && (
				<PaymentFormModal
					showPaymentModal={openRP}
					handleClose={closeRpPopup}
					onFormSubmit={handlePaymentFormSubmit}
					estimate={currentitem}
				/>
			)}

			{show && (
				<DeleteConfirmation
					show={show}
					handleClose={handleClose}
					customDeleteButtonText={show?.master_status?.name == 'Estimated' ? 'Confirm' : 'Delete'}
					handleDelete={() => {
						// if (show.master_status?.name == 'Estimated' || show.master_status?.name == 'Pending Manager' || show.master_status?.name == 'Pending Customer') {
						if (show.master_status?.name == 'Estimated' || show.master_status?.name == 'Pending Manager' || show.master_status?.name == 'Pending') {
							handleCancelEstimate(show?.id);
						} else {
							handleDeleteDraft(show?.id);
						}
					}}
					// title={(show.master_status?.name == 'Estimated' || show.master_status?.name == 'Pending Manager' || show.master_status?.name == 'Pending Customer') ? 'Confirm Cancel' : 'Confirm Delete'}
					title={(show.master_status?.name == 'Estimated' || show.master_status?.name == 'Pending Manager' || show.master_status?.name == 'Pending') ? 'Confirm Cancel' : 'Confirm Delete'}
					message={
						// (show.master_status?.name == 'Estimated' || show.master_status?.name == 'Pending Manager' || show.master_status?.name == 'Pending Customer')
						(show.master_status?.name == 'Estimated' || show.master_status?.name == 'Pending Manager' || show.master_status?.name == 'Pending')
							? `Are you sure you want to cancel estimate ${show.estimation_number}`
							: `Are you sure you want to delete ${show.draft_number || show.estimation_number}`
					}
				/>
			)}

			{showExportModal && (
				<ExportListItemsModal
					openpopup={true}
					disablepopup={() => {
						setShowExportModal(false);
						dispatch(
							storeHeaderActions({
								showQuickStats: headerActions?.showQuickStats,
								showExport: false
							})
						);
					}}
					filters={filters}
					moduleName={'Orders'}
					onSubmit={exportListSubmitHandler}
					historyApi={api.getExportsHistory('orders')}
					isLoading={exportCompIsLoading}
					exportHistoryPerms={['Show Estimation Export History (Button)']}
				/>
			)}


		</>
	);
};

export default index;
